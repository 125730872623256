import {
  UploadDropzone,
  UploadDropzoneConfig,
} from "@bytescale/upload-widget-react";

const ImageUpload = ({
  path,
  onComplete,
}: {
  path: string;
  onComplete: () => void;
}) => {
  const options: UploadDropzoneConfig = {
    apiKey: "public_kW15bzE8EAdRtP6Qb7BwhRb1uYCo",
    editor: {
      images: {
        crop: false,
        preview: false,
      },
    },
    //maxFileCount: 100,
    multi: true,
    path: {
      folderPath: path,
    },
    locale: {},
    showFinishButton: false, // Note: You must use 'onUpdate' if you set 'showFinishButton: false' (default).
    showRemoveButton: false,
    styles: {
      colors: {
        primary: "#377dff",
      },
    },
  };

  return (
    <UploadDropzone
      options={options}
      onUpdate={onComplete}
      height="200px"
      width={"100%"}
      className="left-0"
    />
  );
};

export default ImageUpload;
