import axios from "axios";
import Recase from "better-recase";

const API_KEY = "public_kW15bzE8EAdRtP6Qb7BwhRb1uYCo";

export interface BytescalePhoto {
  filePath: string;
  fileUrl: string;
  size: number;
  type: string;
  lastModified: string;
}
export const bytescaleInstance = axios.create({
  baseURL: "https://api.bytescale.com/v2/accounts/kW15bzE",
  timeout: 1000,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${API_KEY}`,
  },
});

export const getPhotos = ({
  folderPath,
  jwt,
}: {
  folderPath: string | undefined;
  jwt: string | undefined;
}): Promise<BytescalePhoto[]> => {
  return bytescaleInstance
    .get(`/folders/list?folderPath=${folderPath}`, {
      headers: { "Authorization-Token": jwt },
    })
    .then((response) => {
      console.log(response);
      return Recase.camelCopy(response.data.items);
    });
};

export const deletePhoto = ({
  filePath,
  jwt,
}: {
  filePath: string;
  jwt: string;
}): Promise<void> => {
  return bytescaleInstance.delete(`/files?filePath=${filePath}`, {
    headers: { "Authorization-Token": jwt },
  });
};
