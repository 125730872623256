import { yupResolver } from "@hookform/resolvers/yup";
import { ReactElement } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AnyObjectSchema } from "yup";

interface FormProps {
  title: string;
  isLoading: boolean;
  onSave: (data: any) => Promise<void>;
  children: ReactElement | ((props: any) => ReactElement);
  schema: AnyObjectSchema;
  defaultValues: any;
}
export default function Form({
  isLoading,
  onSave,
  children,
  schema,
  defaultValues = {},
}: FormProps) {
  const formProps = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const handleSaveClick = async (data: any) => {
    await onSave(data);
  };

  return (
    <FormProvider {...formProps}>
      <div className="mt-2">
        {typeof children === "function" ? children({}) : children}
      </div>
      <div className="bg-white py-3 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          disabled={isLoading}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={formProps.handleSubmit(handleSaveClick)}
        >
          {isLoading && (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          {isLoading ? "Saving" : "Save"}
        </button>
      </div>
    </FormProvider>
  );
}
