import { FallbackProps } from "react-error-boundary";

export default function Nuts({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <>
      <div>Nuts! Something went wrong</div>
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    </>
  );
}
