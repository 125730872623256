import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { classNames } from "@/utils/StyleHelpers";
interface CurrencyInputProps {
  name: string;
  label: string;
  defaultValue?: string | number;
}
const CurrencyInput = ({
  name,
  label,
  defaultValue = undefined,
}: CurrencyInputProps) => {
  const { formState, register } = useFormContext();
  const { errors } = formState;
  const hasError = useMemo(() => {
    return errors[name] !== undefined;
  }, [errors]);
  return (
    <div
      className={classNames(
        "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 text-left",
      )}
    >
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {label}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">$</span>
          </div>
          <input
            type="number"
            inputMode={"decimal"}
            className={classNames(
              hasError
                ? "border-red-300 text-red-900"
                : "shadow-sm border-gray-300 focus:ring-blue-500 focus:border-blue-500",
              "max-w-lg block w-full pl-7 sm:max-w-xs sm:text-sm rounded-md appearance:textfield",
            )}
            {...register(name, { value: defaultValue })}
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm" id="price-currency">
              USD
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrencyInput;
