import { useQuery } from "@tanstack/react-query";

import { getShowGallery } from "@/api/headlinr";

const useShowGallery = (id: number | undefined) => {
  return useQuery({
    queryKey: ["ShowGallery", id],
    queryFn: () => getShowGallery(id),
    enabled: !!id,
  });
};

export default useShowGallery;
