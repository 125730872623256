import { useQuery } from "@tanstack/react-query";

import { getAttendeePayment } from "@/api/headlinr";

const useAttendee = (id: number | undefined) => {
  return useQuery({
    queryKey: ["AttendeePayments", id],
    queryFn: () => getAttendeePayment(id),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
};

export default useAttendee;
