import { createColumnHelper } from "@tanstack/react-table";
import { format } from "date-fns";

import ResponsiveTable from "@/components/ResponsiveTable";
import { AttendeeSlim } from "@/types/Attendee";

interface AttendeesTableProps {
  attendees: AttendeeSlim[];
  onClick: (id: number) => void;
}
const columnHelper = createColumnHelper<AttendeeSlim>();
const defaultColumns = [
  columnHelper.accessor("name", {
    cell: (info) => info.getValue(),
    header: "Name",
    footer: (props) => props.column.id,
    meta: { mobileColumn: false, mobileStack: false },
  }),
  columnHelper.accessor("email", {
    cell: (info) => info.getValue(),
    header: "Email",
    footer: (props) => props.column.id,
    meta: { mobileColumn: false, mobileStack: true },
  }),
  columnHelper.accessor("event.location", {
    cell: (info) => info.getValue(),
    header: "Event",
    footer: (props) => props.column.id,
    meta: { mobileColumn: false, mobileStack: true },
  }),
  columnHelper.accessor("event.eventDate", {
    cell: (info) => format(new Date(info.getValue()), "MM/dd/yyyy"),
    header: "Event Date",
    footer: (props) => props.column.id,
    meta: { mobileColumn: false, mobileStack: true },
  }),
  columnHelper.display({
    id: "actions",
    cell: "View",
    meta: { mobileColumn: false, mobileStack: false },
  }),
];

function AttendeesSlimTable({ attendees, onClick }: AttendeesTableProps) {
  const handleClick = (attendee: AttendeeSlim) => {
    onClick(attendee.id);
  };

  return (
    <ResponsiveTable
      filterable={true}
      data={attendees}
      columns={defaultColumns}
      onClick={handleClick}
    />
  );
}

export default AttendeesSlimTable;
