import axios from "axios";

const URL = "https://headlinr.auth0.com";
const params = {
  grant_type: "http://auth0.com/oauth/grant-type/password-realm",
  audience: "https://api.headlinr.io",
  scope: "openid profile",
  realm: "Client-Users",
  client_id: "JhOhTROvJ2WUcKZwKF0RI2XvGWNVFk93",
};

export const auth0Instance = axios.create({
  baseURL: URL,
  timeout: 15000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const login = (username: string, password: string) => {
  return auth0Instance.post(`/oauth/token`, {
    ...params,
    username,
    password,
  });
};
