import React from "react";
import { useNavigate } from "react-router-dom";

import CurrencyInput from "@/components/forms/CurrencyInput";
import DropDownInput from "@/components/forms/DropDownInput";
import ModalForm from "@/components/forms/ModalForm";
import MultiInput from "@/components/forms/MultiInput";
import TextAreaInput from "@/components/forms/TextAreaInput";
import TextInput from "@/components/forms/TextInput";
import ToggleInput from "@/components/forms/ToggleInput";
import EventFormSchema from "@/constants/schemas/EventFormSchema";
import { useToasts } from "@/context/ToastContext";
import EventsTable from "@/events/components/EventsTable";
import useAddEvent from "@/hooks/network/useAddEvent";
import useEvents from "@/hooks/network/useEvents";
import useTours from "@/hooks/network/useTours";
import useModalForm from "@/hooks/useModalForm";
import Layout from "@/templates/Layout";

function Events() {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const {
    data: events,
    isLoading,
    refetch,
  } = useEvents({ includeHistorical: true });
  const { data: tours } = useTours();
  const { isModalOpen, modalError, openModal, closeModal, setModalError } =
    useModalForm();
  const { mutate: addEvent, isPending: isAddingEvent } = useAddEvent({
    onError: (error: any) => {
      setModalError(error);
    },
    onSuccess: async () => {
      closeModal();
      addToast("event_added", {
        message: "Event Added",
        level: "success",
      });
      await refetch();
    },
  });
  const handleAddEvent = (data: any) => {
    const tourId = data.tourId === -1 ? null : data.tourId;
    addEvent({ ...data, tourId });
  };

  const routeToEvent = (id: number) => {
    navigate(`/events/${id}`);
  };

  return (
    <Layout isLoading={isLoading || isAddingEvent}>
      <div className="grid grid-cols-1 gap-4 lg:col-span-3">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Events</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the events in your account.
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
              onClick={openModal}
            >
              Add Event
            </button>
          </div>
        </div>
        <EventsTable events={events || []} onClick={routeToEvent} />
      </div>
      <ModalForm
        title={"Add Event"}
        isLoading={isAddingEvent}
        isOpen={isModalOpen}
        close={closeModal}
        onSave={handleAddEvent}
        schema={EventFormSchema}
        defaultValues={{
          active: true,
          eventDate: new Date().toISOString().split("T")[0],
          location: "",
          venue: "",
          passCount: 0,
          passPrice: 0,
          description: "",
          exclusives: [],
        }}
      >
        <>
          {modalError && <div className="text-red-700">Error Adding Event</div>}
          <div className="space-y-6 sm:space-y-5">
            <ToggleInput name={"active"} label={"Active"} />
            <DropDownInput
              name={"tourId"}
              label={"Tour"}
              items={(tours || []).map(({ id, name }) => ({
                value: id,
                display: name,
              }))}
            />
            <TextInput type="date" name={"eventDate"} label={"Date"} />
            <TextInput name={"location"} label={"Location"} />
            <TextInput name={"venue"} label={"Venue"} />
            <TextInput name={"passCount"} label={"Pass Count"} />
            <CurrencyInput name={"passPrice"} label={"Pass Price"} />
            <TextAreaInput name={"description"} label={"Description"} />
            <MultiInput name={"exclusives"} label={"Exclusives"} />
          </div>
        </>
      </ModalForm>
    </Layout>
  );
}

export default Events;
