import {
  BuildingOfficeIcon,
  EnvelopeIcon,
  TicketIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { parseISO, startOfToday } from "date-fns";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Card, { CardButton } from "@/components/Card";
import ConfirmationModal from "@/components/ConfirmationModal";
import Slideover from "@/components/Slideover";
import StackedDate from "@/components/StackedDate";
import Roster from "@/events/components/Roster";
import useClientContext from "@/hooks/network/useClientContext";
import useEvent from "@/hooks/network/useEvent";
import useEvents from "@/hooks/network/useEvents";
import useExportRoster from "@/hooks/network/useExportRoster";
import { useAuth } from "@/hooks/useAuth";
import useModal from "@/hooks/useModal";
import AltLayout from "@/templates/AltLayout";
import { sortEventsByDateAsc } from "@/utils/SortUtils";

export default function Home() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { data: clientContext } = useClientContext();
  const { data: events, isPending: isPendingEvents } = useEvents({ includeHistorical: true });
  const [selectedEventId, setSelectedEventId] = useState<number | undefined>();
  const { data: event, isLoading: isLoadingEvent } = useEvent(selectedEventId);
  const { isModalOpen, openModal, closeModal } = useModal();
  const [rosterEventId, setRosterEventId] = useState<number | undefined>();
  const { mutate: exportRoster, isPending } = useExportRoster({
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      setRosterEventId(undefined);
      closeConfirmation();
    },
  });
  const {
    isModalOpen: isConfirmationOpen,
    openModal: openConfirmation,
    closeModal: closeConfirmation,
  } = useModal();

  const routeToEvent = (eventId: number) => {
    navigate(`/events/${eventId}`);
  };

  const routeToAttendee = (attendeeId: number) => {
    navigate(`/attendees/${attendeeId}`);
  };

  const handleExportRoster = async () => {
    exportRoster(rosterEventId || 0);
  };
  const showRoster = (eventId: number) => {
    setSelectedEventId(eventId);
    openModal();
  };

  const upcomingEvents =
    events
      ?.filter((event) => parseISO(event.eventDate) >= startOfToday())
      .sort(sortEventsByDateAsc)
      .slice(0, 5) || [];

  return (
    <AltLayout isLoading={isPendingEvents}>
      <div className="grid grid-cols-1 gap-4 lg:col-span-3">
        <div className="hidden md:block bg-white shadow">
          <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
            <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
              <div className="flex-1 min-w-0">
                {/* Profile */}
                <div className="flex items-center">
                  <img
                    className="hidden h-16 w-16 rounded-full sm:block"
                    src={user?.picture}
                    alt=""
                  />
                  <div>
                    <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                      <dt className="sr-only">Company</dt>
                      <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                        <BuildingOfficeIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        {clientContext?.clientName}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-lg leading-6 font-medium text-gray-900">
              Upcoming Events
            </h2>
            <div className="mt-2 grid grid-cols-1 gap-5">
              {upcomingEvents.length === 0 && <p>No upcoming events</p>}
              {upcomingEvents.map((event) => (
                <Card
                  key={event.eventKey}
                  title={event.location}
                  subtitle={event.venue}
                  iconComponent={<StackedDate date={event.eventDate} />}
                >
                  <CardButton
                    title={"View Roster"}
                    icon={UserGroupIcon}
                    onClick={() => showRoster(event.id)}
                  />
                  <CardButton
                    title={"Email Roster"}
                    icon={EnvelopeIcon}
                    onClick={() => {
                      setRosterEventId(event.id);
                      openConfirmation();
                    }}
                  />
                  <CardButton
                    title={"View Event"}
                    icon={TicketIcon}
                    onClick={() => routeToEvent(event.id)}
                  />
                </Card>
              ))}
            </div>
          </div>
        </div>
        <Slideover
          title={"Event Roster"}
          subtitle={event?.location || ""}
          detail={event?.venue || ""}
          open={isModalOpen}
          isLoading={isLoadingEvent}
          closeSlideover={closeModal}
        >
          <Roster
            attendees={event?.attendees || []}
            onClick={(id: number) => {
              routeToAttendee(id);
            }}
          />
        </Slideover>
        <ConfirmationModal
          message={"Confirm Email Roster?"}
          isOpen={isConfirmationOpen}
          isLoading={isPending}
          onCancel={closeConfirmation}
          onConfirm={handleExportRoster}
        />
      </div>
    </AltLayout>
  );
}
