import { useFormContext } from "react-hook-form";

import { classNames } from "@/utils/StyleHelpers";
interface TextInputProps {
  name: string;
  label: string;
  rows?: number;
  defaultValue?: string | null;
}
const TextInput = ({ name, label, rows = 8 }: TextInputProps) => {
  const { formState, register } = useFormContext();
  const { errors } = formState;
  const hasError = errors[name] !== undefined;

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 text-left">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {label}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <textarea
          rows={rows}
          className={classNames(
            hasError ? "border-red-300 text-red-900" : "",
            "block max-w-lg w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md",
          )}
          {...register(name)}
        />
      </div>
    </div>
  );
};

export default TextInput;
