import { useMutation } from "@tanstack/react-query";

import { resendConfirmation } from "@/api/headlinr";

interface useResendConfirmationProps {
  onError: (error: any) => void;
  onSuccess: (data: any) => void;
}
const useResendConfirmation = ({
  onSuccess,
  onError,
}: useResendConfirmationProps) => {
  return useMutation({
    mutationFn: (attendeeId: number) => resendConfirmation(attendeeId),
    onError: (error) => {
      onError(error);
    },
    onSuccess: (data) => {
      onSuccess(data);
    },
  });
};

export default useResendConfirmation;
