import {
  CogIcon,
  HomeIcon,
  MapPinIcon,
  QuestionMarkCircleIcon,
  TicketIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";

export const navigation = [
  { name: "Home", href: "/", icon: HomeIcon, current: true },
  { name: "Events", href: "/events", icon: TicketIcon, current: false },
  { name: "Tours", href: "/tours", icon: MapPinIcon, current: false },
  {
    name: "Attendees",
    href: "/attendees",
    icon: UserGroupIcon,
    current: false,
  },
  // {
  //   name: "Galleries",
  //   href: "/galleries",
  //   icon: CameraIcon,
  //   current: false,
  // },
];
export const secondaryNavigation = [
  { name: "Settings", href: "#", icon: CogIcon },
  { name: "Help", href: "#", icon: QuestionMarkCircleIcon },
];
