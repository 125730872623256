import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    name: yup.string().required(),
    passCount: yup.number().optional(),
    passPrice: yup.number().optional(),
    description: yup.string().required(),
  })
  .required();

export default schema;
