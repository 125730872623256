import { format } from "date-fns";

import Badge from "@/components/Badge";
import Event from "@/types/Event";

interface EventHeaderProps {
  event: Event | null;
  onAddAttendeeClick: () => void;
  onSendNotification: () => void;
  onTourClick?: () => void;
}

function EventHeader({
  event,
  onAddAttendeeClick,
  onSendNotification,
  onTourClick = undefined,
}: EventHeaderProps) {
  if (!event) return null;
  return (
    <section aria-labelledby="profile-overview-title">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div className="flex items-center space-x-5">
          <div>
            <p className="text-sm font-medium text-gray-600">
              {event && format(new Date(event.eventDate), "MMM do, yyyy")}
            </p>
            <h1 className="text-2xl font-bold text-gray-900">
              {event.location}
            </h1>
            <p className="text-sm font-medium text-gray-500">{event.venue}</p>
            {event.tour && onTourClick && (
              <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                <button
                  className="hover:cursor-pointer mt-2 flex items-center text-sm text-gray-500"
                  onClick={onTourClick}
                >
                  <Badge text={event.tour?.name} />
                </button>
              </div>
            )}
          </div>
        </div>
        {/*<Stats />*/}
        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
          <button
            onClick={onAddAttendeeClick}
            type="button"
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
          >
            Add Attendee
          </button>
          <button
            type="button"
            onClick={onSendNotification}
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
          >
            Send Notification
          </button>
        </div>
      </div>
    </section>
  );
}
export default EventHeader;
