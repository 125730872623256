import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    eventDate: yup.date().required(),
    location: yup.string().required(),
    venue: yup.string().required(),
    passPrice: yup.number().required(),
    passCount: yup.number().required(),
    description: yup.string().required(),
    exclusives: yup.array().required(),
    tourId: yup.number().optional(),
  })
  .required();

export default schema;
