import Attendee from "@/types/Attendee";
import Event from "@/types/Event";

export const sortEventsByDateAsc = (a: Event, b: Event) => {
  return new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime();
};

export const sortAttendeesByLastName = (a: Attendee, b: Attendee) => {
  return a.lastName < b.lastName ? -1 : 1;
};
