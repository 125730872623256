import { createColumnHelper } from "@tanstack/react-table";
import { formatRelative } from "date-fns";

import ResponsiveTable from "@/components/ResponsiveTable";
import Event from "@/types/Event";
import Notification from "@/types/Notification";

interface NotificationsTableProps {
  notifications: Notification[];
  onClick: (id: number) => void;
  filterable?: boolean;
}

const columnHelper = createColumnHelper<Notification>();
const defaultColumns = [
  columnHelper.accessor("type", {
    cell: (info) => info.getValue(),
    header: () => <span>Type</span>,
    footer: (props) => props.column.id,
  }),
  columnHelper.accessor("createdAt", {
    cell: (info) => formatRelative(new Date(info.getValue()), new Date()),
    header: () => <span>Created</span>,
    footer: (props) => props.column.id,
  }),
  columnHelper.accessor("user.email", {
    cell: (info) => info.getValue(),
    header: () => <span>Created By</span>,
    footer: (props) => props.column.id,
  }),
  columnHelper.display({
    id: "actions",
    cell: () => <span>View</span>,
  }),
];
function NotificationsTable({
  notifications,
  onClick,
  filterable = true,
}: NotificationsTableProps) {
  const handleClick = (event: Event) => {
    onClick(event.id);
  };

  return (
    <ResponsiveTable
      filterable={filterable}
      data={notifications}
      columns={defaultColumns}
      onClick={handleClick}
    />
  );
}

export default NotificationsTable;
