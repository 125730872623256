import { useMutation } from "@tanstack/react-query";

import { EventInput, updateEvent } from "@/api/headlinr";

interface useUpdateEventProps {
  onError: (error: any) => void;
  onSuccess: (data: any) => Promise<void>;
}
const useUpdateEvent = ({ onError, onSuccess }: useUpdateEventProps) => {
  return useMutation({
    mutationFn: ({ id, event }: { id: number; event: EventInput }) => {
      return updateEvent(id, event);
    },
    onError: (error) => {
      onError(error);
    },
    onSuccess: async (data) => {
      await onSuccess(data);
    },
  });
};

export default useUpdateEvent;
