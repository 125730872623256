import {
  ArrowDownIcon,
  ArrowUpIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  RowData,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { ReactNode, useState } from "react";

import DebouncedText from "@/components/forms/DebouncedText";
import useActiveBreakpoint from "@/hooks/useActiveBreakpoint";
import { classNames } from "@/utils/StyleHelpers";

declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    mobileColumn?: boolean | undefined;
    mobileStack?: boolean | undefined;
    mobileHeader?: string | null;
  }
}
const ResponsiveRow = ({
  row,
  onClick,
  isMobile = false,
}: {
  row: Row<any>;
  onClick: (e: any) => void;
  isMobile?: boolean;
}) => {
  const columnCount = row.getVisibleCells().length;
  const firstColumn = row.getVisibleCells().slice(0, 1)[0];
  const otherColumns = row.getVisibleCells().slice(1, columnCount);
  return (
    <tr
      key={row.id}
      className="bg-white cursor-pointer hover:bg-gray-50"
      onClick={() => onClick(row.original)}
    >
      <td className="max-w-0 px-6 py-4 whitespace-nowrap overflow-hidden text-md sm:text-sm text-gray-900">
        {flexRender(
          firstColumn.column.columnDef.cell,
          firstColumn.getContext(),
        )}
        {otherColumns.map((cell) => {
          const mobileHeader = cell.column.columnDef.meta?.mobileHeader;
          const mobileStack = cell.column.columnDef.meta?.mobileStack;
          return (
            <dl
              key={cell.id}
              className={classNames(
                !mobileStack ? "hidden" : "",
                "sm:hidden text-md font-normal",
              )}
            >
              {mobileHeader && (
                <dt className="text-md text-gray-900">{mobileHeader}</dt>
              )}
              <dd className="mt-1 text-md truncate text-gray-700">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </dd>
            </dl>
          );
        })}
      </td>
      {otherColumns.map((cell) => {
        const showOnMobile = cell.column.columnDef.meta?.mobileColumn || false;
        if (isMobile && !showOnMobile) return null;
        return (
          <td
            key={`d-${cell.id}`}
            className={classNames(
              "table-cell max-w-0 px-6 py-4 whitespace-nowrap overflow-hidden text-sm text-gray-900",
            )}
          >
            <div className="flex">
              <div className="group inline-flex space-x-2 text-sm">
                <p className="text-gray-500">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </p>
              </div>
            </div>
          </td>
        );
      })}
    </tr>
  );
};

const TableHeaderSearch = ({
  columnsCount,
  onFilter,
  columnSearch = null,
}: {
  columnsCount: number;
  onFilter: (e: string) => void;
  columnSearch?: ReactNode;
}) => {
  return (
    <tr>
      <th
        colSpan={columnsCount}
        className="px-1 py-1 md:px-6 md:py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        <div className="flex flex-wrap justify-between">
          {columnSearch && (
            <div className="flex self-center p-2 lg:p-0">{columnSearch}</div>
          )}
          <div className="mt-1 w-full lg:w-48 relative rounded-md shadow-sm">
            <DebouncedText
              onChange={onFilter}
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </th>
    </tr>
  );
};

interface ResponsiveTableProps {
  data: any[];
  columns: any[];
  showFooter?: boolean;
  filterable?: boolean;
  onClick: (e: any) => void;
  columnSearch?: ReactNode;
}

const ResponsiveTable = ({
  data,
  columns,
  showFooter = false,
  filterable = false,
  onClick = () => {},
  columnSearch = null,
}: ResponsiveTableProps) => {
  const { breakpoint } = useActiveBreakpoint();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const instance = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const isMobile = breakpoint === "default";
  return (
    <div className="overflow-x-auto shadow overflow-hidden shadow sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          {filterable && (
            <TableHeaderSearch
              columnsCount={columns.length}
              onFilter={(e: string) => setGlobalFilter(e)}
              columnSearch={columnSearch}
            />
          )}
          {instance.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const showOnMobile =
                  header.column.columnDef.meta?.mobileColumn || false;
                if (isMobile && !showOnMobile) return null;
                return (
                  <th
                    key={header.id}
                    scope="col"
                    className={classNames(
                      "table-cell px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider",
                    )}
                  >
                    <button
                      className={classNames(
                        header.column.getCanSort()
                          ? "cursor-pointer select-none"
                          : "",
                        "flex ",
                      )}
                      onClick={header.column.getToggleSortingHandler()}
                      title={
                        header.column.getCanSort()
                          ? header.column.getNextSortingOrder() === "asc"
                            ? "Sort ascending"
                            : header.column.getNextSortingOrder() === "desc"
                              ? "Sort descending"
                              : "Clear sort"
                          : undefined
                      }
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {{
                        asc: <ArrowUpIcon className="h-4 ml-1" />,
                        desc: <ArrowDownIcon className="h-4 ml-1" />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </button>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {instance.getRowModel().rows.map((row) => (
            <ResponsiveRow
              onClick={onClick}
              key={row.id}
              row={row}
              isMobile={isMobile}
            />
          ))}
        </tbody>
        {showFooter && (
          <tfoot>
            {instance.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext(),
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
    </div>
  );
};

export default ResponsiveTable;
