import { useNavigate } from "react-router-dom";

import AttendeesSlimTable from "@/attendees/components/AttendeesSlimTable";
import useAttendees from "@/hooks/network/useAttendees";
import Layout from "@/templates/Layout";

function Attendees() {
  const navigate = useNavigate();
  const { data: attendees, isLoading, error } = useAttendees();

  const routeToAttendee = (id: number) => {
    navigate(`/attendees/${id}`);
  };
  return (
    <Layout isLoading={isLoading}>
      <div className="grid grid-cols-1 gap-4 lg:col-span-3">
        <>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Attendees</h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the attendees in your account.
              </p>
            </div>
          </div>
          {error && <div>Error loading Attendees</div>}
          {!error && attendees && (
            <AttendeesSlimTable
              attendees={attendees}
              onClick={routeToAttendee}
            />
          )}
        </>
      </div>
    </Layout>
  );
}

export default Attendees;
