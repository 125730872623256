import { useQuery } from "@tanstack/react-query";

import { getTour } from "@/api/headlinr";

const useTour = (id: number | undefined) => {
  return useQuery({
    queryKey: ["Tours", id],
    queryFn: () => getTour(id),
    enabled: !!id,
  });
};

export default useTour;
