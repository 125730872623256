import { Switch } from "@headlessui/react";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { classNames } from "@/utils/StyleHelpers";
interface ToggleInputProps {
  name: string;
  label: string;
}
const ToggleInput = ({ name, label }: ToggleInputProps) => {
  const { control, register } = useFormContext();

  useEffect(() => {
    register(name);
  }, [register]);

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 text-left">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {label}
      </label>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <>
            <Switch
              checked={value}
              onChange={onChange}
              className={classNames(
                value === true ? "bg-blue-600" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  value === true ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                )}
              />
            </Switch>
          </>
        )}
      />
    </div>
  );
};

export default ToggleInput;
