import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required(),
    phone: yup.string().required(),
    passCount: yup.number().optional(),
  })
  .required();

export default schema;
