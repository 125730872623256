import jwt, { JwtPayload } from "jsonwebtoken";
import {
  createContext,
  Dispatch,
  FunctionComponent,
  ReactNode,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";

import { setAxiosAuthHeader } from "@/api/headlinr";
import useStorageLogin from "@/hooks/useStorageLogin";

interface AuthUser {
  name: string;
  nickname: string;
  picture: string;
}

interface AuthContextInterface {
  isAuthenticated: boolean;
  accessToken: string | null;
  idToken: string | null;
  user: AuthUser | null;
}

const AuthContext = createContext<AuthContextInterface>({
  isAuthenticated: false,
  accessToken: null,
  idToken: null,
  user: null,
});

const AuthDispatchContext = createContext<Dispatch<ActionInterface>>(() => {});

const useAuthContext = () => useContext(AuthContext);
const useAuthDispatch = () => useContext(AuthDispatchContext);

export enum Action {
  LoginStarted = "LOGIN_STARTED",
  LoginSuccess = "LOGIN_SUCCESS",
  Logout = "LOGOUT",
}

interface ActionInterface {
  type: Action;
  payload: any;
}

const authReducer: Reducer<AuthContextInterface, ActionInterface> = (
  state: AuthContextInterface,
  action: ActionInterface,
) => {
  const decodedIdToken = jwt.decode(action.payload.idToken) as JwtPayload;
  const user = decodedIdToken
    ? {
        name: decodedIdToken["name"],
        nickname: decodedIdToken["nickname"],
        picture: decodedIdToken["picture"],
      }
    : {
        name: "",
        nickname: "",
        picture: "",
      };

  switch (action.type) {
    case Action.LoginSuccess:
      setAxiosAuthHeader(action.payload.accessToken);
      return {
        ...state,
        accessToken: action.payload.accessToken,
        idToken: action.payload.idToken,
        user: { ...user },
        isAuthenticated: true,
      };
    case Action.Logout:
      return {
        ...state,
        accessToken: null,
        idToken: null,
        user: null,
        isAuthenticated: false,
      };
    default:
      return { ...state };
  }
};

const initialAuthState: AuthContextInterface = {
  isAuthenticated: false,
  accessToken: null,
  idToken: null,
  user: null,
};
interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: FunctionComponent<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialAuthState);
  const { storageLogin } = useStorageLogin();

  useEffect(() => {
    storageLogin().then(
      (result: { accessToken: string; idToken: string } | null) => {
        if (result !== null) {
          const { accessToken, idToken } = result;
          dispatch({
            type: Action.LoginSuccess,
            payload: {
              accessToken,
              idToken,
            },
          });
        }
      },
    );
  }, [storageLogin]);

  return (
    <AuthContext.Provider value={{ ...state }}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
};
export { AuthContext, AuthProvider, useAuthContext, useAuthDispatch };
