import "./index.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { ErrorInfo } from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";

import App from "@/App";
import { AuthProvider } from "@/context/AuthContext";
import { ToastProvider } from "@/context/ToastContext";
import Nuts from "@/Nuts";

const queryClient = new QueryClient();
// if (process.env.NODE_ENV === "development") {
//   console.log("starting worker");
//   worker.start();
// }

const myErrorHandler = (error: Error, info: ErrorInfo) => {
  console.log("Error Handler: ", error, info);
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ToastProvider>
          <ErrorBoundary FallbackComponent={Nuts} onError={myErrorHandler}>
            <App />
          </ErrorBoundary>
        </ToastProvider>
      </QueryClientProvider>
    </AuthProvider>
  </React.StrictMode>,
);
