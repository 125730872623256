import { useMutation } from "@tanstack/react-query";

import { updateEventTour } from "@/api/headlinr";

interface useUpdateEventTourProps {
  onError: (error: any) => void;
  onSuccess: (data: any) => Promise<void>;
}
const useUpdateEventTour = ({
  onError,
  onSuccess,
}: useUpdateEventTourProps) => {
  return useMutation({
    mutationFn: ({ id, tourId }: { id: number; tourId: number }) => {
      return updateEventTour(id, tourId);
    },
    onError: (error) => {
      onError(error);
    },
    onSuccess: async (data) => {
      await onSuccess(data);
    },
  });
};

export default useUpdateEventTour;
