import { useQuery } from "@tanstack/react-query";

import { getClientContext } from "@/api/headlinr";

const useClientContext = () => {
  return useQuery({
    queryKey: ["ClientContext"],
    queryFn: () => getClientContext(),
  });
};

export default useClientContext;
