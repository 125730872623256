import { TrashIcon } from "@heroicons/react/24/outline";

import Toggle from "@/components/Toggle";
import { useToasts } from "@/context/ToastContext";
import ImageUpload from "@/events/components/ImageUpload";
import useDeletePhoto from "@/hooks/network/useDeletePhoto";
import useEventGallery from "@/hooks/network/useEventGallery";
import useGalleryPhotos from "@/hooks/network/useGalleryPhotos";
import useUpdateEventGallery from "@/hooks/network/useUpdateEventGallery";

interface EventGalleryProps {
  eventId: number;
}
const EventGallery = ({ eventId }: EventGalleryProps) => {
  const { addToast } = useToasts();
  const { data: gallery, refetch: refetchGallery } = useEventGallery(eventId);
  const { data: photos, refetch: refetchPhotos } = useGalleryPhotos({
    folderPath: gallery?.folderPath,
    jwt: gallery?.apiKey,
  });

  const { mutate: updateEventGallery } = useUpdateEventGallery({
    onError: (error) => {
      console.error("Failed to update gallery", error);
    },
    onSuccess: async () => {
      addToast("gallery_updated", {
        message: "Gallery Updated",
        level: "success",
      });
      await refetchGallery();
    },
  });

  const { mutate: deletePhoto } = useDeletePhoto({
    onError: (error) => {
      console.error("Failed to delete photo", error);
    },
    onSuccess: async () => {
      await refetchPhotos();
    },
  });

  const handleDelete = async (filePath: string) => {
    if (!!gallery?.apiKey && !!filePath) {
      deletePhoto({ filePath, jwt: gallery.apiKey });
    }
  };

  const handlePublishedToggle = async (published: boolean) => {
    console.log("published", published);
    updateEventGallery({
      id: eventId,
      published,
    });
  };

  return (
    <div className="divide-y divide-gray-200 space-y-8">
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 pt-8 md:grid-cols-3">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Gallery Details
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            View gallery details and manage published status.
          </p>
        </div>

        <div className="md:col-span-2">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
            <div className="col-span-full">
              <label
                htmlFor="current-password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Published
              </label>
              <div className="mt-2">
                <Toggle
                  enabled={gallery?.published || false}
                  onChange={handlePublishedToggle}
                />
              </div>
            </div>
            <div className="col-span-full">
              <label
                htmlFor="current-password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Gallery Link
              </label>
              <div className="mt-2">
                <a
                  href={gallery?.publicUrl}
                  target="_blank"
                  className="text-blue-500"
                  rel="noreferrer"
                >
                  {gallery?.publicUrl}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-full">
          <div>
            {gallery && (
              <ImageUpload
                path={gallery.folderPath}
                onComplete={refetchPhotos}
              />
            )}
          </div>
        </div>
      </div>
      <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        {(photos || []).map((photo) => (
          <li key={photo.filePath} className="relative">
            <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
              <img
                src={`${photo.fileUrl.replace("/raw/", "/image/")}?w=240&h=168`}
                alt=""
                className="pointer-events-none object-cover group-hover:opacity-75"
              />
              <button
                type="button"
                className="absolute inset-0 focus:outline-none"
              >
                <span className="sr-only">
                  View details for {photo.filePath}
                </span>
              </button>
            </div>
            <div className="flex justify-between mt-2">
              <div>
                <p className="pointer-events-none block truncate text-sm font-medium text-gray-900">
                  {photo.filePath.split("/").pop()}
                </p>
                <p className="pointer-events-none block text-sm font-medium text-gray-500">
                  1.2MB
                </p>
              </div>
              <div>
                <TrashIcon
                  onClick={() => handleDelete(photo.filePath)}
                  className="cursor-pointer h-4 w-4 text-gray-500"
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EventGallery;
