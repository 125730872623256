import { formatRelative } from "date-fns";
import { v4 as uuidv4 } from "uuid";

import Notification from "@/types/Notification";
interface NotificationProps {
  notification: Notification;
}
const getDestinations = (destinations: string) => {
  return JSON.parse(destinations);
};
const NotificationDetail = ({ notification }: NotificationProps) => {
  const destinations = getDestinations(notification.destinations);

  return (
    <>
      <p className="text-sm leading-6 text-gray-800">{notification.message}</p>{" "}
      <div className="sm:pr-4">
        <dt className="inline text-gray-500">Sent on</dt>{" "}
        <dd className="inline text-gray-700">
          <time dateTime={notification.createdAt}>
            {formatRelative(new Date(notification.createdAt), new Date())}
          </time>
        </dd>
      </div>
      <ul>
        {destinations.map((d: any) => (
          <li key={uuidv4()}>
            <p className="text-xs leading-6 text-gray-500">{d}</p>
          </li>
        ))}
      </ul>
    </>
  );
};

export default NotificationDetail;
