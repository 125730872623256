import { createColumnHelper } from "@tanstack/react-table";
import { format } from "date-fns";

import ResponsiveTable from "@/components/ResponsiveTable";
import Attendee from "@/types/Attendee";

interface AttendeesTableProps {
  attendees: Attendee[];
  onClick: (id: number) => void;
}
const columnHelper = createColumnHelper<Attendee>();
const defaultColumns = [
  columnHelper.accessor((row) => `${row.firstName} ${row.lastName}`, {
    id: "name",
    header: "Name",
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
    meta: { mobileColumn: true, mobileStack: true },
  }),
  columnHelper.accessor("lastName", {
    cell: (info) => info.getValue(),
    header: "Last Name",
    footer: (props) => props.column.id,
    meta: { mobileColumn: false, mobileStack: false },
  }),
  columnHelper.accessor("email", {
    cell: (info) => info.getValue(),
    header: "Email",
    footer: (props) => props.column.id,
    meta: { mobileColumn: false, mobileStack: true },
  }),
  columnHelper.accessor("purchasedAt", {
    cell: (info) => format(new Date(info.getValue()), "MM/dd/yyyy"),
    header: "Purchased",
    footer: (props) => props.column.id,
    meta: { mobileColumn: false, mobileStack: true },
  }),
  columnHelper.display({
    id: "actions",
    cell: "View",
    meta: { mobileColumn: false, mobileStack: false },
  }),
];

function AttendeesTable({ attendees, onClick }: AttendeesTableProps) {
  const handleClick = (attendee: Attendee) => {
    onClick(attendee.id);
  };

  return (
    <ResponsiveTable
      filterable={true}
      data={attendees}
      columns={defaultColumns}
      onClick={handleClick}
    />
  );
}

export default AttendeesTable;
