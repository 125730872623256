import axios from "axios";
import Recase from "better-recase";

import Attendee, { AttendeeSlim } from "@/types/Attendee";
import Event, { EventMetrics } from "@/types/Event";
import Gallery from "@/types/Gallery";
import Notification from "@/types/Notification";
import Payment from "@/types/Payment";
import Tour from "@/types/Tour";

export const setAxiosAuthHeader = (accessToken: string) => {
  headlinrInstance.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
};

export const headlinrInstance = axios.create({
  baseURL: import.meta.env.HEADLINR_API_BASE_URL,
  timeout: 15000,
  headers: {
    "Content-Type": "application/json",
  },
});

export default interface ClientContext {
  clientName: string;
  metrics: any;
  lastUpdated: string;
}
export interface EventInput {
  active: boolean;
  eventDate: string;
  location: string;
  venue: string;
  passCount: number;
  passPrice: number;
  description: string;
  exclusives: string[];
  tourId?: number;
}

export interface NotificationInput {
  sms: boolean;
  email: boolean;
  smsMessage: string;
  emailMessage: string;
}
export interface TourInput {
  name: string;
  description: string;
  defaultPassCount: number;
  defaultPassPrice: number;
  defaultDescription: string;
  active: boolean;
}

export interface AttendeeInput {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  passCount: number;
}

export interface AttendeeAdjustment {
  type: string;
  toEventId?: number;
  passCount?: number;
}

export const getClientContext = (): Promise<ClientContext> => {
  return headlinrInstance
    .get(`/context`)
    .then((response) => Recase.camelCopy(response.data));
};

export const getEvents = (includeHistorical = true): Promise<Event[]> => {
  const url = includeHistorical ? "/events?include_historical=true" : "/events";
  return headlinrInstance
    .get(url)
    .then((response) => Recase.camelCopy(response.data));
};
export const getEvent = (id: number | undefined): Promise<Event> => {
  return headlinrInstance
    .get(`/events/${id}`)
    .then((response) => Recase.camelCopy(response.data));
};

export const getEventGallery = (id: number | undefined): Promise<Gallery> => {
  return headlinrInstance
    .get(`/events/${id}/gallery`)
    .then((response) => Recase.camelCopy(response.data));
};

export const getShowGallery = (id: number | undefined): Promise<Gallery> => {
  return headlinrInstance
    .get(`/events/${id}/show_gallery`)
    .then((response) => Recase.camelCopy(response.data));
};

export const updateEventGallery = (
  id: number | undefined,
  published: boolean,
): Promise<Gallery> => {
  return headlinrInstance
    .put(`/events/${id}/gallery`, { published })
    .then((response) => Recase.camelCopy(response.data));
};

export const updateShowGallery = (
  id: number | undefined,
  published: boolean,
): Promise<Gallery> => {
  return headlinrInstance
    .put(`/events/${id}/show_gallery`, { published })
    .then((response) => Recase.camelCopy(response.data));
};

export const getEventRosterExport = (
  id: number | undefined,
): Promise<Event> => {
  return headlinrInstance
    .get(`/events/${id}?export=true`)
    .then((response) => Recase.camelCopy(response.data));
};
export const addEvent = (event: EventInput) => {
  return headlinrInstance.post("/events", Recase.snakeCopy(event));
};

export const getEventMetrics = (
  id: number | undefined,
): Promise<EventMetrics> => {
  return headlinrInstance
    .get(`/metrics/events/${id}`)
    .then((response) => Recase.camelCopy(response.data));
};

export const getNotifications = (
  eventId: number | undefined,
): Promise<Notification[]> => {
  return headlinrInstance
    .get(`/events/${eventId}/notifications`)
    .then((response) => Recase.camelCopy(response.data));
};
export const addNotification = (
  eventId: number,
  notification: NotificationInput,
) => {
  return headlinrInstance.post(
    `/events/${eventId}/notifications`,
    Recase.snakeCopy(notification),
  );
};
export const updateEvent = (id: number, event: EventInput) => {
  return headlinrInstance.put(`/events/${id}`, {
    event: Recase.snakeCopy(event),
  });
};

export const updateEventTour = (id: number, tourId: number) => {
  return headlinrInstance.put(`/events/${id}`, {
    event: Recase.snakeCopy({ tourId }),
  });
};
export const getAttendees = (): Promise<AttendeeSlim[]> => {
  return headlinrInstance
    .get(`/attendees`)
    .then((response) => Recase.camelCopy(response.data));
};
export const getAttendee = (id: number | undefined): Promise<Attendee> => {
  return headlinrInstance
    .get(`/attendees/${id}`)
    .then((response) => Recase.camelCopy(response.data));
};
export const addAttendee = (id: number, attendee: AttendeeInput) => {
  return headlinrInstance.post(
    `/events/${id}/attendees`,
    Recase.snakeCopy(attendee),
  );
};

export const resendConfirmation = (attendeeId: number) => {
  return headlinrInstance.post(`/attendees/${attendeeId}/confirmation`);
};
export const addAttendeeAdjustment = (
  attendeeId: number,
  attendeeAdjustment: AttendeeAdjustment,
) => {
  return headlinrInstance.post(
    `/attendees/${attendeeId}/adjustments`,
    Recase.snakeCopy(attendeeAdjustment),
  );
};
export const updateAttendee = (id: number, attendee: AttendeeInput) => {
  const attendeeParams = {
    attendee: Recase.snakeCopy(attendee),
  };
  return headlinrInstance.put(`/attendees/${id}`, attendeeParams);
};

export const getAttendeePayment = (
  id: number | undefined,
): Promise<Payment> => {
  return headlinrInstance
    .get(`/attendees/${id}/payment`)
    .then((response) => Recase.camelCopy(response.data));
};

export const addTour = (tour: TourInput) => {
  return headlinrInstance.post("/tours", Recase.snakeCopy(tour));
};

export const getTours = (): Promise<Tour[]> => {
  return headlinrInstance
    .get(`/tours`)
    .then((response) => Recase.camelCopy(response.data));
};
export const getTour = (id: number | undefined): Promise<Tour> => {
  return headlinrInstance
    .get(`/tours/${id}`)
    .then((response) => Recase.camelCopy(response.data));
};

export const getTourEvents = (id: number | undefined): Promise<Event[]> => {
  return headlinrInstance
    .get(`/tours/${id}/events`)
    .then((response) => Recase.camelCopy(response.data));
};
export const updateTour = (id: number, tour: TourInput) => {
  return headlinrInstance.put(`/tours/${id}`, Recase.snakeCopy(tour));
};

export const updateTourEvents = (id: number, active: boolean) => {
  return headlinrInstance.put(`/tours/${id}/events`, { active });
};

export const getGalleries = (): Promise<Gallery[]> => {
  return headlinrInstance
    .get(`/galleries`)
    .then((response) => Recase.camelCopy(response.data));
};

export const getGallery = (id: number | undefined): Promise<Gallery> => {
  return headlinrInstance
    .get(`/galleries/${id}`)
    .then((response) => Recase.camelCopy(response.data));
};
