import { createColumnHelper } from "@tanstack/react-table";

import ResponsiveTable from "@/components/ResponsiveTable";
import Tour from "@/types/Tour";

interface ToursTableProps {
  tours: Tour[];
  onClick: (id: number) => void;
}
const columnHelper = createColumnHelper<Tour>();
const defaultColumns = [
  columnHelper.accessor("name", {
    cell: (info) => info.getValue(),
    header: () => <span>Name</span>,
    footer: (props) => props.column.id,
    meta: { mobileColumn: false, mobileStack: false },
  }),
  columnHelper.accessor("description", {
    cell: (info) => info.getValue(),
    header: () => <span>Description</span>,
    footer: (props) => props.column.id,
    meta: { mobileColumn: false, mobileStack: false },
  }),
  columnHelper.accessor("eventCount", {
    cell: (info) => info.getValue(),
    header: () => <span>Events</span>,
    footer: (props) => props.column.id,
    meta: { mobileColumn: false, mobileStack: false },
  }),
  columnHelper.display({
    id: "actions",
    cell: () => <span>View</span>,
    meta: { mobileColumn: false, mobileStack: false },
  }),
];

function ToursTable({ tours, onClick }: ToursTableProps) {
  const handleClick = (tour: Tour) => {
    onClick(tour.id);
  };

  return (
    <ResponsiveTable
      filterable={true}
      data={tours}
      columns={defaultColumns}
      onClick={handleClick}
    />
  );
}

export default ToursTable;
