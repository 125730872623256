import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AttendeeInput } from "@/api/headlinr";
import AttendeesTable from "@/attendees/components/AttendeesTable";
import CurrencyInput from "@/components/forms/CurrencyInput";
import DropDownInput from "@/components/forms/DropDownInput";
import Form from "@/components/forms/Form";
import ModalForm from "@/components/forms/ModalForm";
import MultiInput from "@/components/forms/MultiInput";
import SelectInput from "@/components/forms/SelectInput";
import TextAreaInput from "@/components/forms/TextAreaInput";
import TextInput from "@/components/forms/TextInput";
import ToggleInput from "@/components/forms/ToggleInput";
import Modal from "@/components/Modal";
import Tabs from "@/components/Tabs";
import AttendeeFormSchema from "@/constants/schemas/AttendeeFormSchema";
import EventFormSchema from "@/constants/schemas/EventFormSchema";
import EventTourSchema from "@/constants/schemas/EventTourSchema";
import NotificationSchema from "@/constants/schemas/NotificationSchema";
import { useToasts } from "@/context/ToastContext";
import EventGallery from "@/events/components/EventGallery";
import EventHeader from "@/events/components/EventHeader";
import ShowGallery from "@/events/components/ShowGallery";
import useAddAttendee from "@/hooks/network/useAddAttendee";
import useAddNotification from "@/hooks/network/useAddNotification";
import useClientContext from "@/hooks/network/useClientContext";
import useEvent from "@/hooks/network/useEvent";
import useNotifications from "@/hooks/network/useNotifications";
import useTours from "@/hooks/network/useTours";
import useUpdateEvent from "@/hooks/network/useUpdateEvent";
import useUpdateEventTour from "@/hooks/network/useUpdateEventTour";
import useModalForm from "@/hooks/useModalForm";
import NotificationsList from "@/notifications/components/NotifcationsList";
import NotificationDetail from "@/notifications/components/NotificationDetail";
import Layout from "@/templates/Layout";
import Notification from "@/types/Notification";

const tabs = ["Details", "Attendees", "Notifications", "Gallery", "Show Photos"];

function Event() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { data: clientContext } = useClientContext();
  const { data: event, isLoading, refetch } = useEvent(parseInt(id || ""));
  const { data: notifications } = useNotifications(parseInt(id || ""));
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const { isModalOpen, modalError, openModal, closeModal, setModalError } =
    useModalForm();
  const { data: tours } = useTours();

  const {
    isModalOpen: isNotificationModalOpen,
    openModal: openNotificationModal,
    closeModal: closeNotificationModal,
  } = useModalForm();

  const {
    isModalOpen: isNotificationDetailModalOpen,
    openModal: openNotificationDetailModal,
    closeModal: closeNotificationDetailModal,
  } = useModalForm();

  const {
    isModalOpen: isTourUpdateOpen,
    openModal: openTourUpdateModal,
    closeModal: closeTourUpdateModal,
  } = useModalForm();

  const [selectedNotification, setSelectedNotification] =
    useState<Notification | null>(null);

  const {
    mutateAsync: updateEvent,
    isPending: isUpdating,
    error: updateError,
  } = useUpdateEvent({
    onError: () => {},
    onSuccess: async () => {
      addToast("event_updated", {
        message: "Event Updated",
        level: "success",
      });
    },
  });

  const { mutateAsync: updateEventTour } = useUpdateEventTour({
    onError: () => {},
    onSuccess: async () => {
      closeTourUpdateModal();
      addToast("event_tour_updated", {
        message: "Tour Updated",
        level: "success",
      });
    },
  });

  const { mutate: createNotification, isPending: isCreatingNotification } =
    useAddNotification({
      onError: () => {
        addToast("notification_error", {
          message: "Notification Not Sent",
          level: "error",
        });
      },
      onSuccess: async () => {
        closeNotificationModal();
        addToast("notification_added", {
          message: "Notification Sent",
          level: "success",
        });
      },
    });

  const { mutate: addAttendee, isPending: isAddingAttendee } = useAddAttendee({
    onError: (error: any) => {
      setModalError(error.message);
    },
    onSuccess: async () => {
      addToast("attendee_added", {
        message: "Added Attendee Successfully!",
        level: "success",
      });
      closeModal();
      await refetch();
    },
  });

  const attendees = useMemo(() => {
    return event?.attendees || [];
  }, [event]);

  const routeToAttendee = (id: number) => {
    navigate(`/attendees/${id}`);
  };

  const openNotificationDetail = (id: number) => {
    const notification = notifications?.find(
      (notification) => notification.id === id,
    );
    if (notification) {
      setSelectedNotification(notification);
    }
    openNotificationDetailModal();
  };

  const handleUpdate = async (updatedEvent: any) => {
    if (!id) {
      throw Error("Update Tour Error: Undefined tour id");
    }
    const tourId = updatedEvent.tourId === -1 ? null : updatedEvent.tourId;
    await updateEvent({ id: parseInt(id), event: { ...updatedEvent, tourId } });
    await refetch();
  };

  const handleAddAttendee = (attendee: AttendeeInput) => {
    addAttendee({ eventId: parseInt(id || ""), attendee: attendee });
  };

  const handleSendNotification = (data: any) => {
    createNotification({
      eventId: parseInt(id || ""),
      notification: {
        sms: data.notificationType === "SMS",
        smsMessage: data.notificationText,
        email: data.notificationType === "Email",
        emailMessage: data.notificationText,
      },
    });
  };

  const handleUpdateTour = async ({ tourId }: { tourId: number }) => {
    if (!id || !tourId) {
      throw Error("Update Tour Error: Undefined event or tour id");
    }
    await updateEventTour({ id: parseInt(id), tourId });
    await refetch();
  };

  if (!event) return null;
  return (
    <Layout isLoading={isLoading}>
      <div className="grid grid-cols-1 gap-4 lg:col-span-3">
        <EventHeader
          event={event}
          onAddAttendeeClick={openModal}
          onSendNotification={openNotificationModal}
          onTourClick={openTourUpdateModal}
        />
        <main className="rounded-lg bg-white pt-4 pb-16">
          {/*<StatsTwo />*/}

          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="px-4 sm:px-0">
              <Tabs
                options={tabs}
                currentTab={currentTab}
                onClick={(selected) => {
                  setCurrentTab(selected);
                }}
              />
            </div>
            <div className="mt-2">
              {currentTab === "Details" && (
                <>
                  {updateError && <div>ERROR UPDATING</div>}
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                      <div className="pt-8 space-y-6 sm:space-y-5">
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Event Information
                          </h3>
                          <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            View event details and manage status.
                          </p>
                        </div>
                        {event && (
                          <Form
                            title={"Event Details"}
                            isLoading={isUpdating}
                            onSave={handleUpdate}
                            schema={EventFormSchema}
                            defaultValues={{ ...event, tourId: event.tour?.id }}
                          >
                            <div className="space-y-6 sm:space-y-5">
                              <ToggleInput name={"active"} label={"Active"} />
                              <DropDownInput
                                name={"tourId"}
                                label={"Tour"}
                                items={(tours || []).map(({ id, name }) => ({
                                  value: id,
                                  display: name,
                                }))}
                              />
                              <TextInput
                                type="date"
                                name={"eventDate"}
                                label={"Date"}
                              />
                              <TextInput name={"location"} label={"Location"} />
                              <TextInput name={"venue"} label={"Venue"} />
                              <TextInput
                                type="number"
                                name={"passCount"}
                                label={"Pass Count"}
                              />
                              <CurrencyInput
                                name={"passPrice"}
                                label={"Pass Price"}
                              />
                              <TextAreaInput
                                name={"description"}
                                label={"Description"}
                              />
                              <MultiInput
                                name={"exclusives"}
                                label={"Exclusives"}
                              />
                            </div>
                          </Form>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {currentTab === "Attendees" && (
                <AttendeesTable
                  attendees={attendees}
                  onClick={routeToAttendee}
                />
              )}
              {currentTab === "Notifications" && (
                <>
                  <NotificationsList
                    notifications={notifications || []}
                    onClick={openNotificationDetail}
                  />
                  <Modal
                    isLoading={false}
                    close={closeNotificationDetailModal}
                    isOpen={isNotificationDetailModalOpen}
                    title={"Notification"}
                  >
                    <>
                      {selectedNotification && (
                        <NotificationDetail
                          notification={selectedNotification}
                        />
                      )}
                    </>
                  </Modal>
                </>
              )}
              {currentTab === "Gallery" && clientContext && event && (
                <EventGallery eventId={event.id} />
              )}
              {currentTab === "Show Photos" && clientContext && event && (
                <ShowGallery eventId={event.id} />
              )}
            </div>
          </div>
        </main>
      </div>

      <div className="grid">
        <ModalForm
          title={"Add Attendee"}
          isLoading={isAddingAttendee}
          isOpen={isModalOpen}
          close={closeModal}
          onSave={handleAddAttendee}
          schema={AttendeeFormSchema}
          defaultValues={{}}
        >
          <>
            {modalError && (
              <div className="text-red-700">Error Adding Attendee</div>
            )}

            <div className="space-y-6 sm:space-y-5">
              <TextInput name={"firstName"} label={"First Name"} />
              <TextInput name={"lastName"} label={"Last Name"} />
              <TextInput type={"email"} name={"email"} label={"Email"} />
              <TextInput name={"phone"} label={"Phone"} />
              <TextInput
                type="number"
                name={"passCount"}
                label={"Pass Count"}
              />
              <TextInput name={"confirmation"} label={"Confirmation Number"} />
            </div>
          </>
        </ModalForm>
      </div>

      <ModalForm
        title={"Send Notification"}
        isLoading={isCreatingNotification}
        isOpen={isNotificationModalOpen}
        close={closeNotificationModal}
        onSave={handleSendNotification}
        schema={NotificationSchema}
        defaultValues={{
          notificationType: "Email",
          notificationText: "",
        }}
      >
        <>
          {modalError && (
            <div className="text-red-700">Error Sending Notification</div>
          )}

          <div className="space-y-6 sm:space-y-5">
            <SelectInput
              name={"notificationType"}
              label={"Notification Type"}
              options={["Email", "SMS"]}
            />
            <TextAreaInput name={"notificationText"} label={"Message Text"} />
          </div>
        </>
      </ModalForm>

      <ModalForm
        title={"Update Tour"}
        isOpen={isTourUpdateOpen}
        isLoading={false}
        close={closeTourUpdateModal}
        onSave={handleUpdateTour}
        schema={EventTourSchema}
        defaultValues={{ tourId: event.tour?.id }}
      >
        <DropDownInput
          name={"tourId"}
          label={"Tour"}
          items={(tours || []).map(({ id, name }) => ({
            value: id,
            display: name,
          }))}
        />
      </ModalForm>
    </Layout>
  );
}

export default Event;
