import { Capacitor } from "@capacitor/core";
import { PropsWithChildren, useCallback, useState } from "react";

import Breadcrumbs from "@/components/Breadcrumbs";
import DesktopNav from "@/components/nav/DesktopNav";
import Header from "@/components/nav/Header";
import MobileNav from "@/components/nav/MobileNav";
import Spinner from "@/components/Spinner";
import NotificationList from "@/components/ToastList";
import { useAuth } from "@/hooks/useAuth";
import { classNames } from "@/utils/StyleHelpers";

interface HeadlinrTemplateProps {
  isLoading?: boolean | undefined;
}
const isNative = Capacitor.isNativePlatform();

const Layout = ({
  isLoading = false,
  children,
}: PropsWithChildren<HeadlinrTemplateProps>) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { user, logout } = useAuth();

  const toggleSidebar = useCallback(() => {
    setSidebarOpen(!sidebarOpen);
  }, [setSidebarOpen]);

  return (
    <>
      <NotificationList />
      <div
        className={classNames("min-h-full", isNative === true ? "pt-14" : "")}
      >
        <MobileNav isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
        <DesktopNav />

        <main className="lg:pl-64 flex flex-col flex-1">
          <Header
            name={user?.name}
            avatarUrl={user?.picture}
            toggleSidebar={toggleSidebar}
            logout={logout}
          />
          <div className="md:hidden">
            <Breadcrumbs />
          </div>
          <main className="mt-4 sm:mt-6 pb-8">
            <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
              {/* Main 3 column grid */}
              <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
                {isLoading ? <Spinner /> : children}
              </div>
            </div>
          </main>
        </main>
      </div>
    </>
  );
};

export default Layout;
