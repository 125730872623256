import { useQuery } from "@tanstack/react-query";

import { getEvents } from "@/api/headlinr";

interface UseEventsProps {
  includeHistorical?: boolean;
}
const useEvents = ({ includeHistorical = true }: UseEventsProps) => {
  return useQuery({
    queryKey: ["Events", includeHistorical],
    queryFn: () => getEvents(includeHistorical),
    enabled: true,
  });
};

export default useEvents;
