import { useQuery } from "@tanstack/react-query";

import { getTourEvents } from "@/api/headlinr";

const useTourEvents = (id: number | undefined) => {
  return useQuery({
    queryKey: ["TourEvents", id],
    queryFn: () => getTourEvents(id),
    enabled: !!id,
  });
};

export default useTourEvents;
