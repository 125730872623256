import { useQuery } from "@tanstack/react-query";

import { getAttendee } from "@/api/headlinr";

const useAttendee = (id: number | undefined) => {
  return useQuery({
    queryKey: ["Attendees", id],
    queryFn: () => getAttendee(id),
    enabled: !!id,
  });
};

export default useAttendee;
