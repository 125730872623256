import { HomeIcon } from "@heroicons/react/20/solid";
import { get } from "lodash";
import { useMatches, useNavigate } from "react-router-dom";

const Breadcrumbs = () => {
  const matches = useMatches();
  const navigate = useNavigate();
  const crumbs = matches
    .filter(({ pathname }) => pathname !== "/")
    .map((match, index) => {
      return {
        name: get(match.handle, "title", ""),
        path: match.pathname,
        isCurrent: index === matches.length - 1,
      };
    });

  return (
    <nav
      className="flex border-b border-gray-200 bg-white"
      aria-label="Breadcrumb"
    >
      <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
        <li className="flex">
          <div className="flex items-center">
            <button
              onClick={() => navigate("/")}
              className="text-gray-400 hover:text-gray-500"
            >
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </button>
          </div>
        </li>
        {crumbs.map(({ name, path, isCurrent }) => (
          <li key={name} className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <button
                onClick={() => navigate(path)}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={isCurrent ? "page" : undefined}
              >
                {name}
              </button>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
