import { useQuery } from "@tanstack/react-query";

import { getEventGallery } from "@/api/headlinr";

const useEventGallery = (id: number | undefined) => {
  return useQuery({
    queryKey: ["EventGallery", id],
    queryFn: () => getEventGallery(id),
    enabled: !!id,
  });
};

export default useEventGallery;
