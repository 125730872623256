import { Link, NavLink } from "react-router-dom";

import imgUrl from "@/assets/images/logo-monochrome-light.svg";
import { navigation, secondaryNavigation } from "@/constants/navigation";
import { classNames } from "@/utils/StyleHelpers";

const DesktopNav = () => {
  return (
    <div className="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
      <div className="flex flex-col flex-grow bg-blue-600 pt-5 pb-4 overflow-y-auto">
        <div className="flex items-center flex-shrink-0 px-2">
          <img className="h-12 w-auto" src={imgUrl} alt="Headlinr logo" />
        </div>
        <nav
          className="mt-5 flex-1 flex flex-col divide-y divide-cyan-800 overflow-y-auto"
          aria-label="Sidebar"
        >
          <div className="px-2 space-y-1">
            {navigation.map((item) => {
              const current = false;
              return (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className={({ isActive }) =>
                    classNames(
                      isActive ? "bg-blue-800" : "hover:bg-blue-500",
                      "text-white group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md",
                    )
                  }
                  aria-current={current ? "page" : undefined}
                >
                  <item.icon
                    className="mr-4 flex-shrink-0 h-6 w-6 text-blue-200"
                    aria-hidden="true"
                  />
                  {item.name}
                </NavLink>
              );
            })}
          </div>
          <div className="mt-6 pt-6">
            <div className="px-2 space-y-1">
              {secondaryNavigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white hover:bg-blue-500"
                >
                  <item.icon
                    className="mr-4 h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default DesktopNav;
