import { useEffect, useState } from "react";

import getActiveBreakpoint from "@/hooks/getActiveBreakpoint";

export const debounce = <A extends unknown[]>(
  callback: (...args: A) => unknown,
  msDelay: number,
) => {
  let timer: any;

  return (...args: A) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      timer = undefined;
      callback(...args);
    }, msDelay);
  };
};

const useActiveBreakpoint = (msDelay = 100) => {
  const [breakpoint, setBreakpoint] = useState(getActiveBreakpoint());
  const [dimension, setDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const resizeHandler = () => {
      setDimension({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      const newBreakpoint = getActiveBreakpoint();
      if (newBreakpoint !== breakpoint) {
        setBreakpoint(newBreakpoint);
      }
    };

    const handler =
      msDelay <= 0 ? resizeHandler : debounce(resizeHandler, msDelay);

    window.addEventListener("resize", handler);

    return () => window.removeEventListener("resize", handler);
  }, []);

  return { breakpoint, dimension };
};

export default useActiveBreakpoint;
