import {
  MagnifyingGlassIcon,
  TicketIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { useMemo, useState } from "react";

import DebouncedText from "@/components/forms/DebouncedText";
import Attendee from "@/types/Attendee";
import { sortAttendeesByLastName } from "@/utils/SortUtils";

interface RosterProps {
  attendees: Attendee[];
  onClick: (id: number) => void;
}

function Roster({ attendees, onClick }: RosterProps) {
  const [filter, setFilter] = useState("");
  const onFilter = (value: string) => {
    setFilter(value);
  };

  const sorted = useMemo(() => {
    const filtered = attendees.filter((attendee) => {
      if (filter === "") return true;
      return (
        attendee.firstName.toLowerCase().includes(filter.toLowerCase()) ||
        attendee.lastName.toLowerCase().includes(filter.toLowerCase()) ||
        attendee.email.toLowerCase().includes(filter.toLowerCase())
      );
    });

    return filtered.sort(sortAttendeesByLastName);
  }, [attendees, filter]);

  return (
    <div className="w-full">
      <div className="grid m-2">
        <div className="mt-1 w-full relative rounded-md shadow-sm">
          <DebouncedText
            onChange={onFilter}
            className="focus:ring-blue-500 focus:border-blue-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
      <ul className="flex-1 divide-y divide-gray-200 overflow-y-auto">
        {sorted.map((attendee) => (
          <li key={attendee.id}>
            <div className="group relative flex items-center px-5 py-6">
              <button
                onClick={() => onClick(attendee.id)}
                className="-m-1 block flex-1 p-1"
              >
                <div
                  className="absolute inset-0 group-hover:bg-gray-50"
                  aria-hidden="true"
                />
                <div className="relative flex min-w-0 flex-1 items-center">
                  <span className="relative inline-block flex-shrink-0">
                    <UserIcon className="h-10 w-10 rounded-full" />
                    <span
                      className="absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white"
                      aria-hidden="true"
                    />
                  </span>
                  <div className="ml-4 text-left truncate">
                    <p className="truncate text-sm font-medium text-gray-900">
                      {attendee.lastName + ", " + attendee.firstName}
                    </p>
                    <p className="truncate text-sm text-gray-500">
                      {attendee.email}
                    </p>
                  </div>
                  <div className="flex-grow text-right">
                    <p className="truncate text-xs font-medium text-gray-500">
                      <TicketIcon className="h-5 w-5 inline" />
                    </p>
                    <p className="truncate text-md text-gray-900">
                      {attendee.passCount}
                    </p>
                  </div>
                </div>
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Roster;
