import { useQuery } from "@tanstack/react-query";

import { getNotifications } from "@/api/headlinr";

const useNotifications = (eventId: number | undefined) => {
  return useQuery({
    queryKey: ["Notifications", eventId],
    queryFn: () => getNotifications(eventId),
    enabled: !!eventId,
  });
};

export default useNotifications;
