import { format, parseISO } from "date-fns";

interface StackedDateProps {
  date: string;
}
const StackedDate = ({ date }: StackedDateProps) => {
  const dateObj = parseISO(date);
  const month = format(dateObj, "MMM");
  const dayOfWeek = format(dateObj, "EEE");
  const dateOfMonth = format(dateObj, "do");
  return (
    <div className="flex-col justify-center items-center rounded-lg bg-white overflow-hidden shadow-md w-16">
      <div className="bg-blue-500 text-white p-1">
        <p className="text-sm font-semibold text-white uppercase tracking-wide text-center">
          {month}
        </p>
      </div>
      <div className="flex-col justify-center items-center">
        <p className="text-xs text-gray-400 text-center py-1 px-2 leading-none">
          {dayOfWeek}
        </p>
        <p className="text-sm font-bold text-gray-900 text-center pb-2 px-2 leading-none">
          {dateOfMonth}
        </p>
      </div>
    </div>
  );
};

export default StackedDate;
