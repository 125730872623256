import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    active: yup.boolean().required(),
  })
  .required();

export default schema;
