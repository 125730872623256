import { RadioGroup } from "@headlessui/react";
import React from "react";
import { useFormContext } from "react-hook-form";

import { classNames } from "@/utils/StyleHelpers";

const items = [
  {
    name: "Active",
    description: "All events associated with this tour will be Active",
    value: true,
  },
  {
    name: "Inactive",
    description: "All events associated with this tour will be Inactive",
    value: false,
  },
];

interface RadioProps {
  name: string;
  label?: string;
}
const Radio = ({ name }: RadioProps) => {
  const { setValue, register } = useFormContext();
  const registered = register(name);
  const handleChange = (value: boolean) => {
    console.log("changed", value);
    setValue(name, value);
  };

  return (
    <RadioGroup {...registered} onChange={handleChange}>
      <div className="space-y-4">
        {items.map((item) => (
          <RadioGroup.Option
            key={item.name}
            value={item.value}
            className={({ active }) =>
              classNames(
                active
                  ? "border-blue-600 ring-2 ring-blue-600"
                  : "border-gray-300",
                "relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between",
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span className="flex items-center">
                  <span className="flex flex-col text-sm">
                    <RadioGroup.Label
                      as="span"
                      className="font-medium text-gray-900"
                    >
                      {item.name}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="text-gray-500">
                      <span className="block sm:inline">
                        {item.description}
                      </span>
                    </RadioGroup.Description>
                  </span>
                </span>
                <span
                  className={classNames(
                    active ? "border" : "border-2",
                    checked ? "border-blue-600" : "border-transparent",
                    "pointer-events-none absolute -inset-px rounded-lg",
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default Radio;
