import { useMutation } from "@tanstack/react-query";

import { addNotification, NotificationInput } from "@/api/headlinr";

interface useAddNotificationProps {
  onError: (error: any) => void;
  onSuccess: (data: any) => void;
}
const useAddNotification = ({
  onError,
  onSuccess,
}: useAddNotificationProps) => {
  return useMutation({
    mutationFn: ({
      eventId,
      notification,
    }: {
      eventId: number;
      notification: NotificationInput;
    }) => {
      return addNotification(eventId, notification);
    },
    onError: (error) => {
      onError(error);
    },
    onSuccess: (data) => {
      onSuccess(data);
    },
  });
};

export default useAddNotification;
