import { useCallback } from "react";
import { createNotificationContext } from "react-notification-provider";

// You can customize the Toast interface to include whatever props your Toasts need to render.
interface Toast {
  message: string;
  duration?: number;
  level: "success" | "error";
}

// This function creates a React context and hooks for you so you'll want to export these.
const { NotificationProvider: ToastProvider, useNotificationQueue } =
  createNotificationContext<Toast>();

const useToasts = () => {
  const toastQueue = useNotificationQueue();

  const addToast = useCallback(
    (id: string, data: Toast): void => {
      toastQueue.add(id, data);

      setTimeout(() => toastQueue.remove(id), 5000);
    },
    [toastQueue],
  );
  return { ...toastQueue, addToast };
};

export { ToastProvider, useNotificationQueue, useToasts };
