import { useQuery } from "@tanstack/react-query";

import { getAttendees } from "@/api/headlinr";

const useAttendees = () => {
  return useQuery({
    queryKey: ["Attendees"],
    queryFn: () => getAttendees(),
  });
};

export default useAttendees;
