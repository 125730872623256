import { useNavigate } from "react-router-dom";

import { TourInput } from "@/api/headlinr";
import CurrencyInput from "@/components/forms/CurrencyInput";
import ModalForm from "@/components/forms/ModalForm";
import TextAreaInput from "@/components/forms/TextAreaInput";
import TextInput from "@/components/forms/TextInput";
import TourFormSchema from "@/constants/schemas/TourFormSchema";
import { useToasts } from "@/context/ToastContext";
import useAddTour from "@/hooks/network/useAddTour";
import useTours from "@/hooks/network/useTours";
import useModalForm from "@/hooks/useModalForm";
import Layout from "@/templates/Layout";
import ToursTable from "@/tours/components/ToursTable";

function Tours() {
  const navigate = useNavigate();
  const { data: tours, isLoading, error, refetch } = useTours();
  const { isModalOpen, modalError, openModal, closeModal, setModalError } =
    useModalForm();

  const { addToast } = useToasts();

  const { mutate: addTour, isPending: isAdding } = useAddTour({
    onError: (error: any) => {
      setModalError(error.message);
    },
    onSuccess: () => {
      addToast("tour_add", {
        message: "Added Tour Successfully!",
        level: "success",
      });
      closeModal();
      refetch();
    },
  });

  const handleAddTour = (tourInput: TourInput) => {
    setModalError(null);
    addTour(tourInput);
  };

  const routeToTour = (id: number) => {
    navigate(`/tours/${id}`);
  };
  return (
    <Layout isLoading={isLoading}>
      <div className="grid grid-cols-1 gap-4 lg:col-span-3">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Tours</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the Tours in your account.
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              //className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={openModal}
            >
              Add Tour
            </button>
          </div>
        </div>
        {error && <div>Error loading Tours</div>}
        {!error && tours && <ToursTable tours={tours} onClick={routeToTour} />}
      </div>
      <ModalForm
        title={"Add Tour"}
        isOpen={isModalOpen}
        isLoading={isAdding}
        close={closeModal}
        onSave={handleAddTour}
        schema={TourFormSchema}
        defaultValues={{
          name: "",
          description: "",
          defaultPassCount: 0,
          defaultPassPrice: 0,
          defaultDescription: "",
        }}
      >
        <>
          {modalError && <div className="text-red-700">Error Adding Tour</div>}
          <div className="space-y-6 sm:space-y-5">
            <TextInput name={"name"} label={"Name"} />
            <TextAreaInput name={"description"} label={"Description"} />
            <TextInput
              type="number"
              name={"defaultPassCount"}
              label={"Default Pass Count"}
            />
            <CurrencyInput
              name={"defaultPassPrice"}
              label={"Default Pass Price"}
            />
            <TextAreaInput
              name={"defaultDescription"}
              label={"Default Description"}
            />
          </div>
        </>
      </ModalForm>
    </Layout>
  );
}

export default Tours;
