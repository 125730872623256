import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
interface MultiInputProps {
  name: string;
  label: string;
  type?: string;
}
const MultiInput = ({ name, label }: MultiInputProps) => {
  const [current, setCurrent] = useState("");
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name,
  });
  const handleAdd = (val: string) => {
    append(val);
    setCurrent("");
  };

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {label}
      </label>
      <div className="flex mt-1 sm:mt-0 sm:col-span-2">
        <div className="flex-grow">
          <input
            type="text"
            name="testing"
            value={current}
            onChange={(e) => setCurrent(e.target.value)}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
          />
        </div>
        <span className="ml-3">
          <button
            type="button"
            onClick={() => handleAdd(current)}
            className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            <PlusIcon
              className="-ml-2 mr-1 h-5 w-5 text-white"
              aria-hidden="true"
            />
            <span>Add</span>
          </button>
        </span>
      </div>
      <div className="flex justify-end mt-1 sm:mt-0 sm:col-span-3">
        <ul>
          {fields.map((item, index) => (
            <li key={item.id} className="flex mt-1 sm:mt-1 justify-end">
              <Controller
                render={({ field }) => (
                  <span {...field} className="text-xs pr-2">
                    {field.value}
                  </span>
                )}
                name={`${name}.${index}`}
                control={control}
              />
              {/*<button type="button" onClick={() => remove(index)}>Delete</button>*/}
              <div className="justify-center">
                <button
                  type="button"
                  onClick={() => remove(index)}
                  className="inline-flex items-center rounded-full border border-transparent bg-blue-600 px-2 py-2 text-lg font-bold text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  <MinusIcon
                    className="h-2 w-2 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MultiInput;
