import { useFormContext } from "react-hook-form";
interface HiddenInputProps {
  name: string;
  value: string | number | null | undefined;
}
const TextInput = ({ name, value }: HiddenInputProps) => {
  const { register } = useFormContext();

  return <input type="hidden" {...register(name, { value })} />;
};

export default TextInput;
