import { useMutation } from "@tanstack/react-query";

import { updateEventGallery } from "@/api/headlinr";

interface useUpdateEventGalleryProps {
  onError: (error: any) => void;
  onSuccess: (data: any) => Promise<void>;
}
const useUpdateEventGallery = ({
  onError,
  onSuccess,
}: useUpdateEventGalleryProps) => {
  return useMutation({
    mutationFn: ({ id, published }: { id: number; published: boolean }) => {
      return updateEventGallery(id, published);
    },
    onError: (error) => {
      onError(error);
    },
    onSuccess: async (data) => {
      await onSuccess(data);
    },
  });
};

export default useUpdateEventGallery;
