import { format } from "date-fns";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { EventInput, TourInput } from "@/api/headlinr";
import CurrencyInput from "@/components/forms/CurrencyInput";
import Form from "@/components/forms/Form";
import HiddenInput from "@/components/forms/HiddenInput";
import ModalForm from "@/components/forms/ModalForm";
import MultiInput from "@/components/forms/MultiInput";
import Radio from "@/components/forms/Radio";
import TextAreaInput from "@/components/forms/TextAreaInput";
import TextInput from "@/components/forms/TextInput";
import ToggleInput from "@/components/forms/ToggleInput";
import Tabs from "@/components/Tabs";
import EventFormSchema from "@/constants/schemas/EventFormSchema";
import TourFormSchema from "@/constants/schemas/TourFormSchema";
import UpdateTourEventsSchema from "@/constants/schemas/UpdateTourEventsSchema";
import { useToasts } from "@/context/ToastContext";
import EventsTable from "@/events/components/EventsTable";
import useAddEvent from "@/hooks/network/useAddEvent";
import useTour from "@/hooks/network/useTour";
import useTourEvents from "@/hooks/network/useTourEvents";
import useUpdateTour from "@/hooks/network/useUpdateTour";
import useUpdateTourEvents from "@/hooks/network/useUpdateTourEvents";
import useModalForm from "@/hooks/useModalForm";
import Layout from "@/templates/Layout";

const tabs = ["Details", "Events"];

function Tour() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { data: tour, isLoading, refetch } = useTour(parseInt(id || ""));
  const {
    data: events,
    isLoading: isLoadingEvents,
    refetch: refreshTourEvents,
  } = useTourEvents(parseInt(id || ""));

  const { isModalOpen, modalError, openModal, closeModal, setModalError } =
    useModalForm();

  const {
    isModalOpen: isActivateModalOpen,
    openModal: openActivateModal,
    closeModal: closeActivateModal,
    setModalError: setActivateModalError,
  } = useModalForm();

  const {
    mutate: updateTour,
    isPending: isUpdating,
    error: updateTourError,
  } = useUpdateTour({
    onError: () => {
      addToast("tour_updated", {
        message: "Error Updating Tour",
        level: "error",
      });
    },
    onSuccess: () => {
      addToast("tour_updated", {
        message: "Tour Updated",
        level: "success",
      });
      refetch();
    },
  });

  const { mutate: updateTourEvents, isPending: isUpdatingEvents } =
    useUpdateTourEvents({
      onError: () => {
        addToast("tour_events_updated", {
          message: "Error Updating Events",
          level: "error",
        });
      },
      onSuccess: () => {
        closeActivateModal();
        addToast("tour_events_updated", {
          message: "Events Updated",
          level: "success",
        });
        refreshTourEvents();
      },
    });

  const { mutate: addEvent, isPending: isAddingEvent } = useAddEvent({
    onError: (error: any) => {
      setModalError(error);
    },
    onSuccess: () => {
      closeModal();
      addToast("event_added", {
        message: "Event Added",
        level: "success",
      });
      refreshTourEvents();
    },
  });
  const [currentTab, setCurrenTab] = useState(tabs[0]);

  // const { tourMetrics, isLoading: isLoadingTourMetrics, refresh: refreshTourMetrics } = useTourMetrics(id);

  const handleUpdateTour = async (data: TourInput) => {
    if (!id) {
      throw Error("Update Tour Error: Undefined tour id");
    }
    updateTour({
      id: parseInt(id),
      tour: data,
    });
  };

  const handleAddEvent = (data: EventInput) => {
    if (!id) {
      throw Error("Add Tour Event Error: Undefined tour id");
    }
    setModalError(null);
    addEvent(data);
  };

  const handleActivateEvents = ({ active }: { active: boolean }) => {
    if (!id) {
      throw Error("Activate Events Error: Undefined tour id");
    }
    setActivateModalError(null);
    updateTourEvents({ id: parseInt(id), active });
  };

  const routeToEvent = (id: number) => {
    navigate(`/events/${id}`);
  };

  if (!tour) return null;
  return (
    <Layout isLoading={isLoading || isLoadingEvents || isUpdatingEvents}>
      <div className="grid grid-cols-1 gap-4 lg:col-span-3">
        <section aria-labelledby="profile-overview-title">
          <div className="max-w-3xl mx-auto px-0 sm:px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div className="flex items-center space-x-5">
              <div>
                <p className="text-sm font-medium text-gray-600">
                  {tour && format(new Date(), "MMM do, yyyy")}
                </p>
                <h1 className="text-2xl font-bold text-gray-900">
                  {tour.name}
                </h1>
                <p className="text-sm font-medium text-gray-500">
                  {tour.description}
                </p>
              </div>
            </div>
            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
              <button
                onClick={openActivateModal}
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Update Event Status
              </button>
              <button
                onClick={openModal}
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Add Event
              </button>
            </div>
          </div>
        </section>

        <main className="rounded-lg bg-white pt-4 pb-16">
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="px-4 sm:px-0">
              <Tabs
                options={tabs}
                currentTab={currentTab}
                onClick={(selected) => {
                  setCurrenTab(selected);
                }}
              />
            </div>
            <div className="mt-2">
              {currentTab === "Details" && (
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Tour Information
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                          They have what plants crave.
                        </p>
                      </div>
                      <Form
                        title={"Tour Details"}
                        isLoading={isUpdating}
                        onSave={handleUpdateTour}
                        schema={TourFormSchema}
                        defaultValues={tour}
                      >
                        <>
                          {updateTourError && <div>ERROR UPDATING TOUR</div>}
                          <div className="space-y-6 sm:space-y-5">
                            <TextInput name={"name"} label={"Name"} />
                            <TextAreaInput
                              name={"description"}
                              label={"Description"}
                            />
                            <TextInput
                              type="number"
                              name={"defaultPassCount"}
                              label={"Default Pass Count"}
                            />
                            <CurrencyInput
                              name={"defaultPassPrice"}
                              label={"Default Pass Price"}
                            />
                            <TextAreaInput
                              name={"defaultDescription"}
                              label={"Default Description"}
                            />
                          </div>
                        </>
                      </Form>
                    </div>
                  </div>
                </div>
              )}
              {currentTab === "Events" && (
                <>
                  {!isLoadingEvents && (
                    <EventsTable events={events || []} onClick={routeToEvent} />
                  )}
                </>
              )}
            </div>
          </div>
        </main>
      </div>

      <div className="grid">
        <ModalForm
          isLoading={isAddingEvent}
          title={"Add Event"}
          isOpen={isModalOpen}
          close={closeModal}
          onSave={handleAddEvent}
          schema={EventFormSchema}
          defaultValues={{
            active: true,
            eventDate: new Date().toISOString().slice(0, 10),
            location: "",
            venue: "",
            passCount: tour.defaultPassCount,
            passPrice: tour.defaultPassPrice,
            description: tour.defaultDescription,
            exclusives: tour.defaultExclusives,
            tourId: id,
          }}
        >
          <>
            {modalError && (
              <div className="text-red-700">Error Adding Event</div>
            )}

            <div className="space-y-6 sm:space-y-5">
              <ToggleInput name={"active"} label={"Active"} />
              <TextInput type="date" name={"eventDate"} label={"Date"} />
              <TextInput name={"location"} label={"Location"} />
              <TextInput name={"venue"} label={"Venue"} />
              <TextInput name={"passCount"} label={"Pass Count"} />
              <CurrencyInput name={"passPrice"} label={"Pass Price"} />
              <TextAreaInput name={"description"} label={"Description"} />
              <MultiInput name={"exclusives"} label={"Exclusives"} />
              <HiddenInput name={"tourId"} value={id} />
            </div>
          </>
        </ModalForm>

        <ModalForm
          isLoading={isUpdatingEvents}
          title={"Update Event Status"}
          isOpen={isActivateModalOpen}
          close={closeActivateModal}
          onSave={handleActivateEvents}
          schema={UpdateTourEventsSchema}
          defaultValues={{
            active: true,
          }}
        >
          <>
            {modalError && (
              <div className="text-red-700">Error Updating Events</div>
            )}

            <div className="space-y-6 sm:space-y-5">
              <Radio name={"active"} label={"Active"} />
            </div>
          </>
        </ModalForm>
      </div>
    </Layout>
  );
}

export default Tour;
