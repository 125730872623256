import { useState } from "react";

const useModalForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalError, setModalError] = useState<string | null>(null);

  const openModal = () => {
    setModalError(null);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalError(null);
    setIsModalOpen(false);
  };
  return { isModalOpen, modalError, openModal, closeModal, setModalError };
};

export default useModalForm;
