import { Controller, useFormContext } from "react-hook-form";
interface SelectInputProps {
  name: string;
  label: string;
  options: string[];
}
const SelectInput = ({ name, label, options }: SelectInputProps) => {
  const { control } = useFormContext();

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 text-left">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {label}
      </label>
      <fieldset className="mt-4">
        <div className="space-y-4">
          {options.map((option) => (
            <div key={option} className="flex items-center">
              <Controller
                control={control}
                name={name}
                render={({ field }) => (
                  <>
                    <input
                      key={option}
                      {...field}
                      defaultChecked={option === field.value}
                      type={"radio"}
                      value={option}
                      className="h-4 w-4 border-blue-300 text-blue-600 focus:ring-blue-600"
                    />
                    <label
                      htmlFor={option}
                      className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                    >
                      {option}
                    </label>
                  </>
                )}
              />
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
};

export default SelectInput;
