import { useQuery } from "@tanstack/react-query";

import { getPhotos } from "@/api/bytescale";

interface useGalleryPhotosProps {
  folderPath: string | undefined;
  jwt: string | undefined;
}
const useGalleryPhotos = ({ folderPath, jwt }: useGalleryPhotosProps) => {
  return useQuery({
    queryKey: ["GalleryPhotos", folderPath],
    queryFn: () => getPhotos({ folderPath, jwt }),
    enabled: !!jwt && !!folderPath,
  });
};

export default useGalleryPhotos;
