import { useMutation } from "@tanstack/react-query";

import { getEventRosterExport } from "@/api/headlinr";

interface useExportRosterProps {
  onError: (error: any) => void;
  onSuccess: (data: any) => void;
}
const useExportRoster = ({ onError, onSuccess }: useExportRosterProps) => {
  return useMutation({
    mutationFn: (eventId: number) => {
      return getEventRosterExport(eventId);
    },
    onError: (error) => {
      onError(error);
    },
    onSuccess: (data) => {
      onSuccess(data);
    },
  });
};

export default useExportRoster;
