import { createColumnHelper } from "@tanstack/react-table";
import { parseISO, startOfToday } from "date-fns";
import { useMemo, useState } from "react";

import ResponsiveTable from "@/components/ResponsiveTable";
import Toggle from "@/components/Toggle";
import Event from "@/types/Event";
import { classNames } from "@/utils/StyleHelpers";

interface EventsTableProps {
  events: Event[];
  onClick: (id: number) => void;
  filterable?: boolean;
}

const columnHelper = createColumnHelper<Event>();
const defaultColumns = [
  columnHelper.accessor("eventDate", {
    cell: (info) => info.getValue(),
    header: () => <span>Date</span>,
    footer: (props) => props.column.id,
    sortingFn: "datetime",
    meta: { mobileColumn: true, mobileStack: true },
  }),
  columnHelper.accessor("location", {
    cell: (info) => info.getValue(),
    header: () => <span>Location</span>,
    footer: (props) => props.column.id,
    meta: { mobileColumn: false, mobileStack: true },
  }),
  columnHelper.accessor("venue", {
    cell: (info) => info.getValue(),
    header: () => <span>Venue</span>,
    footer: (props) => props.column.id,
    meta: { mobileColumn: false, mobileStack: true },
  }),
  columnHelper.accessor("soldPasses", {
    cell: (info) => {
      const { soldPasses, passCount } = info.row.original;
      const percentage = (soldPasses / passCount) * 100;
      return (
        <span
          className={
            "bg-gray-100 text-gray-600 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium"
          }
        >
          {`${soldPasses} (${percentage.toFixed(0)}%)`}
        </span>
      );
    },
    header: () => <span>Sold</span>,
    footer: (props) => props.column.id,
    meta: { mobileColumn: false, mobileStack: true },
  }),
  columnHelper.accessor("active", {
    cell: (info) => (
      <span
        className={classNames(
          info.getValue()
            ? "bg-green-100 text-green-700"
            : "bg-gray-100 text-gray-600",
          "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium",
        )}
      >
        {info.getValue() ? "Active" : "Inactive"}
      </span>
    ),
    header: () => <span></span>,
    footer: (props) => props.column.id,
    meta: { mobileColumn: false, mobileStack: false },
  }),
  columnHelper.display({
    id: "actions",
    cell: () => <span>View</span>,
    meta: { mobileColumn: true, mobileStack: false },
  }),
];
function EventsTable({ events, onClick, filterable = true }: EventsTableProps) {
  const [excludeHistorical, setExcludeHistorical] = useState(false);
  const handleClick = (event: Event) => {
    onClick(event.id);
  };

  const filteredEvents = useMemo(() => {
    return excludeHistorical
      ? events
      : events.filter((e) => parseISO(e.eventDate) >= startOfToday());
  }, [excludeHistorical]);

  return (
    <ResponsiveTable
      filterable={filterable}
      data={filteredEvents}
      columns={defaultColumns}
      onClick={handleClick}
      columnSearch={
        <EventColumnFilter
          excludeHistorical={excludeHistorical}
          setExcludeHistorical={setExcludeHistorical}
        />
      }
    />
  );
}

const EventColumnFilter = ({
  excludeHistorical,
  setExcludeHistorical,
}: {
  excludeHistorical: boolean;
  setExcludeHistorical: (value: boolean) => void;
}) => {
  return (
    <div>
      <Toggle
        label={"Include Past Events?"}
        enabled={excludeHistorical}
        onChange={() => setExcludeHistorical(!excludeHistorical)}
      />
    </div>
  );
};
export default EventsTable;
