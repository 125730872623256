import { useMutation } from "@tanstack/react-query";

import { addAttendee, AttendeeInput } from "@/api/headlinr";

interface useAddAttendeeProps {
  onError: (error: any) => void;
  onSuccess: (data: any) => void;
}
const useAddAttendee = ({ onError, onSuccess }: useAddAttendeeProps) => {
  return useMutation({
    mutationFn: ({
      eventId,
      attendee,
    }: {
      eventId: number;
      attendee: AttendeeInput;
    }) => {
      return addAttendee(eventId, attendee);
    },
    onError: (error) => {
      onError(error);
    },
    onSuccess: (data) => {
      onSuccess(data);
    },
  });
};

export default useAddAttendee;
