import { useMutation } from "@tanstack/react-query";

import { addAttendeeAdjustment, AttendeeAdjustment } from "@/api/headlinr";

interface useAddAttendeeAdjustmentProps {
  onError: (error: any) => void;
  onSuccess: (data: any) => void;
}
const useAddAttendeeAdjustment = ({
  onError,
  onSuccess,
}: useAddAttendeeAdjustmentProps) => {
  return useMutation({
    mutationFn: ({
      attendeeId,
      attendeeAdjustment,
    }: {
      attendeeId: number;
      attendeeAdjustment: AttendeeAdjustment;
    }) => {
      return addAttendeeAdjustment(attendeeId, attendeeAdjustment);
    },
    onError: (error) => {
      onError(error);
    },
    onSuccess: (data) => {
      onSuccess(data);
    },
  });
};


export default useAddAttendeeAdjustment;
