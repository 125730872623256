import { useMutation } from "@tanstack/react-query";

import { deletePhoto } from "@/api/bytescale";

interface useDeletePhotoProps {
  onError: (error: any) => void;
  onSuccess: (data: any) => Promise<void>;
}
const useDeletePhoto = ({ onError, onSuccess }: useDeletePhotoProps) => {
  return useMutation({
    mutationFn: ({ filePath, jwt }: { filePath: string; jwt: string }) => {
      return deletePhoto({ filePath, jwt });
    },
    onError: (error) => {
      onError(error);
    },
    onSuccess: async (data) => {
      await onSuccess(data);
    },
  });
};

export default useDeletePhoto;
