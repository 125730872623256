import { useMutation } from "@tanstack/react-query";

import { login } from "@/api/auth0";

interface UseLoginProps {
  onError: (error: any) => void;
  onSuccess: (data: any) => void;
}
const useLogin = ({ onSuccess, onError }: UseLoginProps) => {
  return useMutation({
    mutationFn: ({
      username,
      password,
    }: {
      username: string;
      password: string;
    }) => login(username, password),
    onError: (error) => {
      onError(error);
    },
    onSuccess: (data) => {
      onSuccess(data);
    },
  });
};

export default useLogin;
