import { useQuery } from "@tanstack/react-query";

import { getTours } from "@/api/headlinr";

const useTours = () => {
  return useQuery({
    queryKey: ["Tours"],
    queryFn: () => getTours(),
  });
};

export default useTours;
