import { useEffect, useState } from "react";

const debounceInterval = 250;

interface DebouncedTextProps {
  onChange: (e: string) => void;
  className: string;
}

const DebouncedText = ({ onChange, className }: DebouncedTextProps) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue("");
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounceInterval);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      type={"text"}
      onChange={(e) => setValue(e.target.value)}
      className={className}
    />
  );
};

export default DebouncedText;
