import { TicketIcon } from "@heroicons/react/24/outline";

import { classNames } from "@/utils/StyleHelpers";

interface BadgeProps {
  text: string | undefined;
  onClick?: () => void;
}
const Badge = ({ text, onClick = undefined }: BadgeProps) => {
  return (
    <div
      className={classNames(
        onClick ? "hover:bg-blue-200 hover:cursor-pointer" : "",
        "inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800",
      )}
      {...(onClick ? onClick : {})}
    >
      <TicketIcon
        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
        aria-hidden="true"
      />
      {text}
    </div>
  );
};

export default Badge;
