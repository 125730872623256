import { useFormContext } from "react-hook-form";

export declare interface DropDownItem<K, V> {
  value: K;
  display: V;
}
interface DropDownInputProps {
  name: string;
  label: string;
  items: DropDownItem<number, string | number | string>[];
}
export default function DropDownInput({
  name,
  label,
  items,
}: DropDownInputProps) {
  const { register } = useFormContext();
  const registered = register(name);

  const withNull = [{ value: -1, display: "---" }, ...items];

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 text-left">
      <label
        htmlFor="location"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <select
        {...registered}
        defaultValue={"---"}
        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
      >
        {withNull.map((item) => (
          <option key={item.value} value={item.value}>
            {item.display}
          </option>
        ))}
      </select>
    </div>
  );
}
