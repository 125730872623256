import { useMutation } from "@tanstack/react-query";

import { TourInput, updateTour } from "@/api/headlinr";

interface useUpdateTourProps {
  onError: (error: any) => void;
  onSuccess: (data: any) => void;
}
const useUpdateTour = ({ onError, onSuccess }: useUpdateTourProps) => {
  return useMutation({
    mutationFn: ({ id, tour }: { id: number; tour: TourInput }) => {
      return updateTour(id, tour);
    },
    onError: (error) => {
      onError(error);
    },
    onSuccess: (data) => {
      onSuccess(data);
    },
  });
};

export default useUpdateTour;
