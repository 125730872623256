import {
  ArrowsRightLeftIcon,
  EnvelopeOpenIcon,
  UserPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

import { AttendeeInput } from "@/api/headlinr";
import RemoveAttendeeModal from "@/attendees/components/RemoveAttendeeModal";
import Badge from "@/components/Badge";
import ConfirmationModal from "@/components/ConfirmationModal";
import DropDownMenu from "@/components/DropDownMenu";
import DropDownInput from "@/components/forms/DropDownInput";
import Form from "@/components/forms/Form";
import HiddenInput from "@/components/forms/HiddenInput";
import ModalForm from "@/components/forms/ModalForm";
import TextInput from "@/components/forms/TextInput";
import Tabs from "@/components/Tabs";
import AttendeeFormSchema from "@/constants/schemas/AttendeeFormSchema";
import { useToasts } from "@/context/ToastContext";
import useAddAttendeeAdjustment from "@/hooks/network/useAddAttendeeAdjustment";
import useAttendee from "@/hooks/network/useAttendee";
import useAttendeePayment from "@/hooks/network/useAttendeePayment";
import useEvents from "@/hooks/network/useEvents";
import useResendConfirmation from "@/hooks/network/useResendConfirmation";
import useUpdateAttendee from "@/hooks/network/useUpdateAttendee";
import useModalForm from "@/hooks/useModalForm";
import Layout from "@/templates/Layout";

const tabs = ["Attendee Details", "Payment"];

const moveAttendeeSchema = yup
  .object()
  .shape({
    toEventId: yup.number().required(),
    passCount: yup.number().required(),
  })
  .required();

const adjustPassCountSchema = yup
  .object()
  .shape({
    passCount: yup.number().required(),
  })
  .required();

function Attendee() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { addToast } = useToasts();
  const {
    data: attendee,
    isLoading,
    refetch,
  } = useAttendee(parseInt(id || ""));

  const { data: attendeePayment } = useAttendeePayment(parseInt(id || ""));

  const { data: upcomingEvents, isLoading: isLoadingUpcomingEvents } =
    useEvents({ includeHistorical: false });

  const {
    isModalOpen: removeModalOpen,
    openModal: openRemoveModal,
    closeModal: closeRemoveModal,
  } = useModalForm();

  const {
    isModalOpen: moveModalOpen,
    openModal: openMoveModal,
    closeModal: closeMoveModal,
  } = useModalForm();

  const {
    isModalOpen: adjustCountModalOpen,
    openModal: openAdjustCountModal,
    closeModal: closeAdjustCountModal,
  } = useModalForm();

  const {
    isModalOpen: resendModalOpen,
    openModal: openResendModal,
    closeModal: closeResendModal,
  } = useModalForm();

  const { mutate: updateAttendee, isPending: isUpdating } = useUpdateAttendee({
    onError: () => {
      addToast("attendee_updated", {
        message: "Attendee Update Failed!",
        level: "error",
      });
    },
    onSuccess: () => {
      addToast("attendee_updated", {
        message: "Attendee Updated Successfully!",
        level: "success",
      });
      refetch();
    },
  });

  const { mutate: removeAttendee, isPending: isRemovingAttendee } =
    useAddAttendeeAdjustment({
      onError: () => {
        addToast("attendee_updated", {
          message: "Remove Attendee Failed!",
          level: "error",
        });
      },
      onSuccess: () => {
        addToast("attendee_removed", {
          message: "Attendee Removed Successfully!",
          level: "success",
        });
        closeRemoveModal();
        navigate(-1);
      },
    });
  const {
    mutate: adjustAttendee,
    error: adjustAttendeeError,
    isPending: isAddingAdjustment,
  } = useAddAttendeeAdjustment({
    onError: () => {
      addToast("attendee_updated", {
        message: "Update Failed!",
        level: "error",
      });
    },
    onSuccess: () => {
      addToast("attendee_updated", {
        message: "Updated Successfully!",
        level: "success",
      });
      refetch();
    },
  });

  const {
    mutate: adjustPassCount,
    error: adjustPassCountError,
    isPending: isAdjustingPassCount,
  } = useAddAttendeeAdjustment({
    onError: () => {
      addToast("attendee_updated", {
        message: "Update Failed!",
        level: "error",
      });
    },
    onSuccess: () => {
      addToast("attendee_updated", {
        message: "Updated Successfully!",
        level: "success",
      });
      closeAdjustCountModal();
      refetch();
    },
  });

  const { mutate: resendConfirmation, isPending: isResendingConfirmation } =
    useResendConfirmation({
      onError: () => {
        addToast("attendee_confirmation", {
          message: "Confirmation Resend Failed!",
          level: "error",
        });
      },
      onSuccess: () => {
        addToast("attendee_updated", {
          message: "Confirmation Resent Successfully!",
          level: "success",
        });
      },
    });

  const handleRemoveAttendee = () => {
    removeAttendee({
      attendeeId: parseInt(id || ""),
      attendeeAdjustment: {
        type: "remove",
      },
    });
  };

  const handleAdjustPassCount = ({ passCount }: { passCount: number }) => {
    adjustPassCount({
      attendeeId: parseInt(id || ""),
      attendeeAdjustment: {
        type: "reduce",
        passCount,
      },
    });
  };

  const handleMoveAttendee = ({
    toEventId,
    passCount,
  }: {
    toEventId: number;
    passCount: number;
  }) => {
    adjustAttendee({
      attendeeId: parseInt(id || ""),
      attendeeAdjustment: {
        type: "switch",
        toEventId,
        passCount,
      },
    });
    closeRemoveModal();
  };

  const handleResendConfirmation = () => {
    resendConfirmation(parseInt(id || ""));
    closeResendModal();
  };

  const attendeeMenuItems = [
    { icon: XMarkIcon, label: "Remove", onClick: openRemoveModal },
    {
      icon: ArrowsRightLeftIcon,
      label: "Switch Events",
      onClick: openMoveModal,
    },
    {
      icon: UserPlusIcon,
      label: "Adjust Pass Count",
      onClick: openAdjustCountModal,
    },
    {
      icon: EnvelopeOpenIcon,
      label: "Resend Confirmation",
      onClick: openResendModal,
    },
  ];

  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const handleUpdateAttendee = async (data: AttendeeInput) => {
    if (!id) {
      throw Error("Update Tour Error: Undefined tour id");
    }
    updateAttendee({
      id: parseInt(id),
      attendee: data,
    });
  };

  if (!attendee) return null;
  return (
    <Layout isLoading={isLoading || isLoadingUpcomingEvents}>
      <div className="grid grid-cols-1 gap-4 lg:col-span-3">
        <section aria-labelledby="profile-overview-title">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div className="flex items-center space-x-5">
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  {`${attendee.firstName} ${attendee.lastName}`}
                </h1>
                <p className="text-sm font-medium text-gray-500">
                  {attendee.email}
                </p>
                <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <Badge
                      text={`${attendee.passCount.toString()} ${
                        attendee.passCount === 1 ? "Pass" : "Passes"
                      }`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
              <DropDownMenu items={attendeeMenuItems} />
            </div>
          </div>
        </section>

        <main className="rounded-lg bg-white pt-8 pb-16">
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="px-4 sm:px-0">
              <Tabs
                options={tabs}
                currentTab={currentTab}
                onClick={(selected) => {
                  setCurrentTab(selected);
                }}
              />
            </div>
            <div className="mt-2">
              {currentTab === "Attendee Details" && (
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Attendee Information
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                          They have what plants crave.
                        </p>
                      </div>
                      <Form
                        title={"Tour Details"}
                        isLoading={isUpdating}
                        onSave={handleUpdateAttendee}
                        schema={AttendeeFormSchema}
                        defaultValues={{
                          firstName: attendee.firstName,
                          lastName: attendee.lastName,
                          email: attendee.email,
                          phone: attendee.phone,
                        }}
                      >
                        <>
                          <div className="space-y-6 sm:space-y-5">
                            <TextInput
                              name={"firstName"}
                              label={"First Name"}
                            />
                            <TextInput name={"lastName"} label={"Last Name"} />
                            <TextInput name={"email"} label={"Email"} />
                            <TextInput name={"phone"} label={"Phone"} />
                          </div>
                        </>
                      </Form>
                    </div>
                  </div>
                </div>
              )}
              {currentTab === "Payment" && (
                <div className="mx-auto mt-6 max-w-5xl">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Payment Provider
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {attendeePayment?.paymentProvider}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Customer Email
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {attendeePayment?.customerEmail}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Provider Transaction ID
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {attendeePayment?.transactionId}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Purchased At
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {attendeePayment?.purchasedAt}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Status
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {attendeePayment?.status}
                      </dd>
                    </div>
                  </dl>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
      <RemoveAttendeeModal
        isOpen={removeModalOpen}
        onCancel={closeRemoveModal}
        onConfirm={handleRemoveAttendee}
        isLoading={isRemovingAttendee}
      />
      <ModalForm
        title={"Adjust Pass Count"}
        isLoading={isAdjustingPassCount}
        isOpen={adjustCountModalOpen}
        close={closeAdjustCountModal}
        onSave={handleAdjustPassCount}
        schema={adjustPassCountSchema}
        defaultValues={{
          passCount: attendee.passCount,
        }}
      >
        <>
          {adjustPassCountError && (
            <div className="text-red-700">Error Adjusting Pass Count</div>
          )}
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              * Updated Confirmation email will also be sent to the new attendee.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <TextInput
              name={"passCount"}
              type={"number"}
              label={"Pass Count"}
            />
          </div>
        </>
      </ModalForm>
      <ModalForm
        title={"Move Attendee"}
        isLoading={isAddingAdjustment}
        isOpen={moveModalOpen}
        close={closeMoveModal}
        onSave={handleMoveAttendee}
        schema={moveAttendeeSchema}
        defaultValues={{
          toEventId: null,
          passCount: attendee.passCount,
        }}
      >
        <>
          {adjustAttendeeError && (
            <div className="text-red-700">Error Moving Attendee</div>
          )}
          <div className="space-y-6 sm:space-y-5">
            <DropDownInput
              name={"toEventId"}
              label={"New Event"}
              items={(upcomingEvents || []).map(
                ({ id, eventDate, location }) => ({
                  value: id,
                  display: `${location}: ${eventDate}`,
                }),
              )}
            />
            <HiddenInput name={"passCount"} value={attendee.passCount} />
          </div>
        </>
      </ModalForm>

      <ConfirmationModal
        isOpen={resendModalOpen}
        onCancel={closeResendModal}
        onConfirm={handleResendConfirmation}
        isLoading={isResendingConfirmation}
        message={"Are you sure you want to resend the confirmation email?"}
      />

      <div className="grid"></div>
    </Layout>
  );
}

export default Attendee;
