import jwt, { JwtPayload } from "jsonwebtoken";

const isTokenValid = (token: string): boolean => {
  try {
    // Decode the JWT to get the payload
    const decoded: JwtPayload = jwt.decode(token) as JwtPayload;

    // Check if the current time is before the expiration time
    if (decoded && decoded.exp) {
      const currentTimeInSeconds = Math.floor(Date.now() / 1000);
      return currentTimeInSeconds < decoded.exp;
    }

    return false;
  } catch (error) {
    // Handle decoding or other errors
    console.error("Error decoding or validating JWT:", error);
    return false;
  }
};

export { isTokenValid };
