import { Preferences } from "@capacitor/preferences";
import { useCallback, useState } from "react";

import { isTokenValid } from "@/utils/JwtHelper";

const SESSION_KEY = "session";

export interface StorageLoginResult {
  accessToken: string;
  idToken: string;
}
export default function useStorageLogin() {
  const [storageLoginComplete, setStorageLoginComplete] = useState(false);
  const storageLogin =
    useCallback(async (): Promise<StorageLoginResult | null> => {
      try {
        let isValid = false;
        let token = null;
        let idtoken = null;

        const { value } = await Preferences.get({ key: SESSION_KEY });
        if (value !== null) {
          const { accessToken, idToken } = await JSON.parse(value);

          isValid = isTokenValid(accessToken);

          token = accessToken;
          idtoken = idToken;
        }

        setStorageLoginComplete(true);

        return isValid ? { accessToken: token, idToken: idtoken } : null;
      } catch (err) {
        setStorageLoginComplete(true);

        return null;
      }
    }, []);

  const clearStorageLogin = useCallback(async () => {
    await Preferences.remove({ key: SESSION_KEY });
    setStorageLoginComplete(false);
  }, []);

  return { storageLogin, storageLoginComplete, clearStorageLogin };
}
