import { useMutation } from "@tanstack/react-query";

import { updateTourEvents } from "@/api/headlinr";

interface useUpdateTourEventsProps {
  onError: (error: any) => void;
  onSuccess: (data: any) => void;
}
const useUpdateTourEvents = ({
  onError,
  onSuccess,
}: useUpdateTourEventsProps) => {
  return useMutation({
    mutationFn: ({ id, active }: { id: number; active: boolean }) => {
      return updateTourEvents(id, active);
    },
    onError: (error) => {
      onError(error);
    },
    onSuccess: (data) => {
      onSuccess(data);
    },
  });
};

export default useUpdateTourEvents;
