import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    tourId: yup.number().required(),
  })
  .required();

export default schema;
