import { useMutation } from "@tanstack/react-query";

import { addTour, TourInput } from "@/api/headlinr";

interface useAddTourProps {
  onError: (error: any) => void;
  onSuccess: (data: any) => void;
}
const useAddTour = ({ onError, onSuccess }: useAddTourProps) => {
  return useMutation({
    mutationFn: (tour: TourInput) => {
      return addTour(tour);
    },
    onError: (error) => {
      onError(error);
    },
    onSuccess: (data) => {
      onSuccess(data);
    },
  });
};

export default useAddTour;
