import { useMutation } from "@tanstack/react-query";

import { addEvent, EventInput } from "@/api/headlinr";

interface useAddEventProps {
  onError: (error: any) => void;
  onSuccess: (data: any) => void;
}
const useAddEvent = ({ onError, onSuccess }: useAddEventProps) => {
  return useMutation({
    mutationFn: (event: EventInput) => {
      return addEvent(event);
    },
    onError: (error) => {
      onError(error);
    },
    onSuccess: (data) => {
      onSuccess(data);
    },
  });
};

export default useAddEvent;
