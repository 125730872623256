import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Attendee from "@/attendees/Attendee";
import Attendees from "@/attendees/Attendees";
import Event from "@/events/Event";
import Events from "@/events/Events";
import Tour from "@/tours/Tour";
import Tours from "@/tours/Tours";

import Home from "./Home";
import { useAuth } from "./hooks/useAuth";
import Login from "./Login";

const router = createBrowserRouter([
  {
    path: "/",
    handle: {
      title: "Home",
    },
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "events",
        handle: {
          title: "Events",
        },
        children: [
          {
            index: true,
            element: <Events />,
          },
          {
            path: ":id",
            element: <Event />,
            handle: {
              title: "Event Details",
            },
          },
        ],
      },
      {
        path: "attendees",
        handle: {
          title: "Attendees",
        },
        children: [
          {
            index: true,
            element: <Attendees />,
          },
          {
            path: ":id",
            element: <Attendee />,
            handle: {
              title: "Attendee Details",
            },
          },
        ],
      },
      {
        path: "tours",
        handle: {
          title: "Tours",
        },
        children: [
          {
            index: true,
            element: <Tours />,
          },
          {
            path: ":id",
            element: <Tour />,
            handle: {
              title: "Tour Details",
            },
          },
        ],
      },
    ],
  },
]);
function App() {
  const { isAuthenticated, login } = useAuth();

  return (
    <div className="App">
      {isAuthenticated ? (
        <RouterProvider router={router} />
      ) : (
        <Login onLogin={login} />
      )}
    </div>
  );
}

export default App;
