import * as React from "react";
import { createElement, PropsWithChildren, PropsWithoutRef } from "react";

interface CardProps {
  title: string;
  subtitle: string;
  iconComponent?: React.ReactNode;
}
const Card = ({
  title,
  subtitle,
  iconComponent,
  children,
}: PropsWithChildren<CardProps>) => {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="p-5">
        <div className="flex items-center">
          <div className="flex-shrink-0">{iconComponent}</div>
          <div className="ml-5 w-0 flex-1">
            <dl>
              <dt className="text-sm font-medium text-gray-500 truncate">
                {subtitle}
              </dt>
              <dd>
                <div className="text-lg font-medium text-gray-900">{title}</div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      {children && (
        <div className="flex divide-x divide-gray-200 bg-gray-50 px-5 py-3">
          {children}
        </div>
      )}
    </div>
  );
};

interface CardButtonProps {
  title: string;
  icon?: React.ForwardRefExoticComponent<
    PropsWithoutRef<React.SVGProps<SVGSVGElement>>
  >;
  onClick: () => void;
}
export const CardButton = ({
  title,
  icon,
  onClick = () => {},
}: CardButtonProps) => {
  return (
    <div className="flex w-0 flex-1">
      <button
        onClick={onClick}
        className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-1 text-xs font-semibold text-gray-900"
      >
        {icon &&
          createElement(icon, {
            className:
              'hidden sm:block h-5 w-5 text-gray-400" aria-hidden="true',
          })}
        {title}
      </button>
    </div>
  );
};
export default Card;
