import { Preferences } from "@capacitor/preferences";
import { useCallback } from "react";

import { Action, useAuthContext, useAuthDispatch } from "@/context/AuthContext";
import useStorageLogin from "@/hooks/useStorageLogin";

export function useAuth() {
  const { isAuthenticated, user, accessToken } = useAuthContext();
  const { clearStorageLogin } = useStorageLogin();
  const dispatch = useAuthDispatch();

  const logout = useCallback(() => {
    clearStorageLogin().then(() => {
      dispatch({ type: Action.Logout, payload: {} });
    });
  }, []);

  const login = useCallback(
    ({ accessToken, idToken }: { accessToken: string; idToken: string }) => {
      if (accessToken !== null) {
        const payload = { accessToken, idToken };
        Preferences.set({
          key: "session",
          value: JSON.stringify(payload),
        }).then(() => {
          dispatch({
            type: Action.LoginSuccess,
            payload: { accessToken, idToken },
          });
        });
      }
    },
    [],
  );

  return { isAuthenticated, user, accessToken, login, logout } as const;
}
