import { Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { Fragment } from "react";

import { classNames } from "@/utils/StyleHelpers";

interface HeaderProps {
  avatarUrl?: string;
  name?: string;
  searchEnabled?: boolean;
  toggleSidebar: () => void;
  logout: () => void;
}
const Header = ({
  avatarUrl,
  name,
  searchEnabled = false,
  toggleSidebar,
  logout,
}: HeaderProps) => (
  <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:border-none">
    <button
      type="button"
      className="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden"
      onClick={() => toggleSidebar()}
    >
      <span className="sr-only">Open sidebar</span>
      <Bars3Icon className="h-6 w-6" aria-hidden="true" />
    </button>
    {/* Search bar */}
    <div
      className={classNames(
        searchEnabled ? "justify-between" : "justify-end",
        "flex-1 px-4 flex sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8",
      )}
    >
      {searchEnabled && (
        <div className="flex-1 flex">
          <form className="w-full flex md:ml-0" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <div className="relative w-full text-gray-400 focus-within:text-gray-600">
              <div
                className="absolute inset-y-0 left-0 flex items-center pointer-events-none"
                aria-hidden="true"
              >
                <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              <input
                id="search-field"
                name="search-field"
                className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm"
                placeholder="Search transactions"
                type="search"
              />
            </div>
          </form>
        </div>
      )}

      <div className="ml-4 flex items-center md:ml-6">
        {/* Profile dropdown */}
        <Menu as="div" className="ml-3 relative">
          <div>
            <Menu.Button className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
              <img className="h-8 w-8 rounded-full" src={avatarUrl} alt="" />
              <span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
                <span className="sr-only">Open user menu for </span>
                {name}
              </span>
              <ChevronDownIcon
                className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              {/*<Menu.Item>*/}
              {/*  {({ active }) => (*/}
              {/*    <button*/}
              {/*      className={classNames(*/}
              {/*        active ? "bg-gray-100" : "",*/}
              {/*        "block px-4 py-2 text-sm text-gray-700",*/}
              {/*      )}*/}
              {/*    >*/}
              {/*      Your Profile*/}
              {/*    </button>*/}
              {/*  )}*/}
              {/*</Menu.Item>*/}
              {/*<Menu.Item>*/}
              {/*  {({ active }) => (*/}
              {/*    <button*/}
              {/*      className={classNames(*/}
              {/*        active ? "bg-gray-100" : "",*/}
              {/*        "block px-4 py-2 text-sm text-gray-700",*/}
              {/*      )}*/}
              {/*    >*/}
              {/*      Settings*/}
              {/*    </button>*/}
              {/*  )}*/}
              {/*</Menu.Item>*/}
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm text-gray-700",
                    )}
                    onClick={logout}
                  >
                    Logout
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  </div>
);

export default Header;
