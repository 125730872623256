import { Capacitor } from "@capacitor/core";
import { ReactNode, useCallback, useState } from "react";

import DesktopNav from "@/components/nav/DesktopNav";
import Header from "@/components/nav/Header";
import MobileNav from "@/components/nav/MobileNav";
import Spinner from "@/components/Spinner";
import NotificationList from "@/components/ToastList";
import { useAuth } from "@/hooks/useAuth";
import { classNames } from "@/utils/StyleHelpers";

interface HeadlinrTemplateProps {
  children: ReactNode;
  isLoading?: boolean | undefined;
}
const isNative = Capacitor.isNativePlatform();

const AltLayout = ({ children, isLoading = false }: HeadlinrTemplateProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { user, logout } = useAuth();

  const toggleSidebar = useCallback(() => {
    setSidebarOpen(!sidebarOpen);
  }, [setSidebarOpen]);

  return (
    <>
      <NotificationList />
      <div
        className={classNames("min-h-full", isNative === true ? "pt-14" : "")}
      >
        <MobileNav isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
        <DesktopNav />

        <div className="lg:pl-64 flex flex-col flex-1">
          <Header
            name={user?.name}
            avatarUrl={user?.picture}
            toggleSidebar={toggleSidebar}
            logout={logout}
          />
          <main className="flex-1 pb-8">
            {isLoading ? <Spinner /> : children}
          </main>
        </div>
      </div>
    </>
  );
};

export default AltLayout;
