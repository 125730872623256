import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, PropsWithChildren } from "react";

import Spinner from "@/components/Spinner";

interface SlideoverProps {
  title: string;
  subtitle: string;
  detail?: string | null;
  open: boolean;
  isLoading?: boolean;
  closeSlideover: () => void;
}
const Slideover = ({
  title,
  subtitle,
  detail = null,
  open,
  isLoading = false,
  closeSlideover,
  children,
}: PropsWithChildren<SlideoverProps>) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-4 sm:pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col bg-white shadow-xl">
                    <div className="p-4 sm:p-6">
                      <div className="flex items-start justify-between sticky top-0">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          {title}
                          <div className="text-sm font-medium text-gray-500 truncate">
                            {subtitle}
                          </div>
                          {detail && (
                            <div className="text-xs font-medium text-gray-500 truncate">
                              {detail}
                            </div>
                          )}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2"
                            onClick={closeSlideover}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-gray-200">
                      <div className="px-6">
                        <nav className="-mb-px flex space-x-6" />
                      </div>
                    </div>
                    <div className="relative mt-2 sm:mt-6 flex-1 px-0 overflow-y-scroll">
                      {isLoading ? <Spinner /> : children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Slideover;
