import NotificationsTable from "@/notifications/components/NotificationsTable";
import Notification from "@/types/Notification";

interface NotificationsListProps {
  notifications: Notification[];
  onClick: (id: number) => void;
}

function NotificationsList({ notifications, onClick }: NotificationsListProps) {
  return (
    // <div>
    //   {/* Activity list (smallest breakpoint only) */}
    //   <div className="shadow sm:hidden">
    //     <ul className="mt-2 divide-y divide-gray-200 overflow-hidden sh2adow sm:hidden">
    //       {notifications.map((notification) => (
    //         <li key={notification.id}>
    //           <button
    //             onClick={() => onClick(notification.id)}
    //             className="block px-4 py-4 bg-white hover:bg-gray-50"
    //           >
    //             <span className="flex items-center space-x-4">
    //               <span className="flex-1 flex space-x-2 truncate">
    //                 <CurrencyDollarIcon
    //                   className="flex-shrink-0 h-5 w-5 text-gray-400"
    //                   aria-hidden="true"
    //                 />
    //                 <span className="flex flex-col text-gray-500 text-sm truncate">
    //                   <span className="truncate">{notification.createdAt}</span>
    //                   <span>
    //                     <span className="text-gray-900 font-medium">
    //                       {notification.createdAt}
    //                     </span>
    //                   </span>
    //                   <time dateTime={notification.createdAt}>
    //                     {notification.createdAt}
    //                   </time>
    //                 </span>
    //               </span>
    //               <ChevronRightIcon
    //                 className="flex-shrink-0 h-5 w-5 text-gray-400"
    //                 aria-hidden="true"
    //               />
    //             </span>
    //           </button>
    //         </li>
    //       ))}
    //     </ul>
    //
    //     <nav
    //       className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
    //       aria-label="Pagination"
    //     >
    //       <div className="flex-1 flex justify-between">
    //         <button
    //           onClick={() => {}}
    //           className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
    //         >
    //           Previous
    //         </button>
    //         <button
    //           onClick={() => {}}
    //           className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
    //         >
    //           Next
    //         </button>
    //       </div>
    //     </nav>
    //   </div>

    <NotificationsTable
      notifications={notifications}
      onClick={onClick}
      filterable={false}
    />
    // </div>
  );
}

export default NotificationsList;
