import { useQuery } from "@tanstack/react-query";

import { getEvent } from "@/api/headlinr";

const useEvent = (id: number | undefined) => {
  return useQuery({
    queryKey: ["Events", id],
    queryFn: () => getEvent(id),
    enabled: !!id,
  });
};

export default useEvent;
